import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import withMobileDialog from "@material-ui/core/withMobileDialog";

const styles = theme => ({
  table: {
    borderCollapse: "collapse",
    margin: "0 auto",
    textAlign: "center",
    overflow: "auto"
  },
});

class TermsConditionAcunsa extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className="max-w-5xl mx-auto w-full">
        <div>
          <p className="text-center my-4">
          SEGURO DE ASISTENCIA SANITARIA
          </p>
          <p className="text-center my-4">
            <b>PLAN ASISTENSI</b>
          </p>
          <p className="text-center my-4">
            <b>PLAN ASISTENSI PLUS</b>
          </p>
          <p className="text-center my-4">
          CONDICIONES GENERALES
          </p>
        </div>
        <div className="mt-10 px-4">
          <p className="text-left my-4">
            <b>INDICE</b>
          </p>
          <div className="w-full space-y-1 ">
            <div className="flex flex-row items-center justify-between">
              <span className="w-10 text-left">1.</span>
              <span className="flex-1 text-left">ARTÍCULO PRELIMINAR</span>
              {/* <span className="w-12 text-right">4</span> */}
            </div>
            <div className="flex flex-row items-center justify-between">
              <span className="w-10 text-left">2.</span>
              <span className="flex-1 text-left">
                <a href={`#articulo2`}
                  className="cursor-pointer text-purple-500 underline" >
                  DEFINICIONES
                </a>
                </span>
              {/* <span className="w-12 text-right">5</span> */}
            </div>
            <div className="flex flex-row items-center justify-between">
              <span className="w-10 text-left">3.</span>
              <span className="flex-1 text-left">
              <a href={`#articulo3`}
                  className="cursor-pointer text-purple-500 underline" >
                 OBJETO DEL SEGURO
                </a>
              </span>
              {/* <span className="w-12 text-right">10</span> */}
            </div>
            <div className="flex flex-row items-center justify-between font-bold">
              <span className="w-10 text-left">4.</span>
              <span className="flex-1 text-left">
                <a href={`#articulo4`}
                  className="cursor-pointer text-purple-500 underline" >
                 LÍMITES
                </a>
              </span>
              {/* <span className="w-12 text-right">11</span> */}
            </div>
            <div className="flex flex-row items-center justify-between font-bold">
              <span className="w-10 text-left">5.</span>
              <span className="flex-1 text-left">
                <a href={`#articulo5`}
                  className="cursor-pointer text-purple-500 underline" >
                  PERIODOS DE CARENCIA (O PLAZOS DE ESPERA)
                </a>
              </span>
              {/* <span className="w-12 text-right">11</span> */}
            </div>
            <div className="flex flex-row items-center justify-between font-bold">
              <span className="w-10 text-left">6.</span>
              <span className="flex-1 text-left">
                <a href={`#articulo6`}
                  className="cursor-pointer text-purple-500 underline" >
                  RIESGOS EXCLUIDOS
                </a>
              </span>
              {/* <span className="w-12 text-right">11</span> */}
            </div>
            <div className="flex flex-row items-center justify-between">
              <span className="w-10 text-left">7.</span>
              <span className="flex-1 text-left">
                <a href={`#articulo7`}
                  className="cursor-pointer text-purple-500 underline" >
                  PROCEDIMIENTO PARA SOLICITAR LA PRESTACIÓN DE LA PÓLIZA
                </a>
              </span>
              {/* <span className="w-12 text-right">11</span> */}
            </div>
            <div className="flex flex-row items-center justify-between">
              <span className="w-10 text-left">8.</span>
              <span className="flex-1 text-left">
                <a href={`#articulo8`}
                  className="cursor-pointer text-purple-500 underline" >
                  BASES, PÉRDIDA DE DERECHOS Y RESCISIÓN DEL CONTRATO
                </a>
              </span>
              {/* <span className="w-12 text-right">13</span> */}
            </div>
            <div className="flex flex-row items-center justify-between">
              <span className="w-10 text-left">9.</span>
              <span className="flex-1 text-left">
                <a href={`#articulo9`}
                  className="cursor-pointer text-purple-500 underline" >
                  FORMALIZACIÓN Y DURACIÓN DEL SEGURO
                </a>
              </span>
              {/* <span className="w-12 text-right">13</span> */}
            </div>
            <div className="flex flex-row items-center justify-between">
              <span className="w-10 text-left">10.</span>
              <span className="flex-1 text-left">
                <a href={`#articulo10`}
                  className="cursor-pointer text-purple-500 underline" >
                  PAGO DE PRIMAS Y EFECTOS DE SU IMPAGO
                </a>
              </span>
              {/* <span className="w-12 text-right">15</span> */}
            </div>
            <div className="flex flex-row items-center justify-between">
              <span className="w-10 text-left">11.</span>
              <span className="flex-1 text-left">
                <a href={`#articulo11`}
                  className="cursor-pointer text-purple-500 underline" >
                  OTRAS FACULTADES, OBLIGACIONES Y DEBERES
                </a>
              </span>
              {/* <span className="w-12 text-right">15</span> */}
            </div>
            <div className="flex flex-row items-center justify-between">
              <span className="w-10 text-left">12.</span>
              <span className="flex-1 text-left">
                <a href={`#articulo12`}
                  className="cursor-pointer text-purple-500 underline" >
                  ACTUALIZACIÓN ANUAL DE LAS CONDICIONES ECONÓMICAS DE LA PÓLIZA
                </a>
              </span>
              {/* <span className="w-12 text-right">17</span> */}
            </div>
            <div className="flex flex-row items-center justify-between">
              <span className="w-10 text-left">13.</span>
              <span className="flex-1 text-left">
                <a href={`#articulo13`}
                  className="cursor-pointer text-purple-500 underline" >
                  TRATAMIENTO DE DATOS DE CARÁCTER PERSONAL
                </a>
              </span>
              {/* <span className="w-12 text-right">17</span> */}
            </div>
            <div className="flex flex-row items-center justify-between">
              <span className="w-10 text-left">14.</span>
              <span className="flex-1 text-left">
                <a href={`#articulo14`}
                  className="cursor-pointer text-purple-500 underline" >
                  COMUNICACIONES
                </a>
              </span>
              {/* <span className="w-12 text-right">19</span> */}
            </div>
            <div className="flex flex-row items-center justify-between">
              <span className="w-10 text-left">15.</span>
              <span className="flex-1 text-left">
                <a href={`#articulo15`}
                  className="cursor-pointer text-purple-500 underline" >
                  INSTANCIAS DE RECLAMACIÓN
                </a>
              </span>
              {/* <span className="w-12 text-right">19</span> */}
            </div>
            <div className="flex flex-row items-center justify-between">
              <span className="w-10 text-left">16.</span>
              <span className="flex-1 text-left">
                <a href={`#articulo16`}
                  className="cursor-pointer text-purple-500 underline" >
                  PRESCRIPCIÓN
                </a>
              </span>
              {/* <span className="w-12 text-right">20</span> */}
            </div>
            <div className="flex flex-row items-center justify-between">
              <span className="w-10 text-left">17.</span>
              <span className="flex-1 text-left">
                <a href={`#articulo17`}
                  className="cursor-pointer text-purple-500 underline" >
                  INFORMACIÓN AL TOMADOR DEL SEGURO Y A LOS ASEGURADOS
                </a>
              </span>
              {/* <span className="w-12 text-right">20</span> */}
            </div>
            <div className="flex flex-row items-center justify-between">
              <span className="w-10 text-left">18.</span>
              <span className="flex-1 text-left">
                <a href={`#articulo18`}
                  className="cursor-pointer text-purple-500 underline" >
                  SANCIONES INTERNACIONALES. ENTRADA EN VIGOR.
                </a>
              </span>
              {/* <span className="w-12 text-right">21</span> */}
            </div>
            <div className="flex flex-row items-center justify-between pt-3">
              <span className="w-auto pr-1 text-left">ANEXO A – </span>
              <span className="flex-1 text-left">COBERTURAS Y EXCLUSIONES</span>
              {/* <span className="w-12 text-right">22</span> */}
            </div>
            <div className="flex flex-row items-center justify-between pt-1">
              <span className="w-auto pr-1 text-left">SECCIÓN I: </span>
              <span className="flex-1 text-left">CATÁLOGO DE COBERTURAS</span>
              {/* <span className="w-12 text-right">23</span> */}
            </div>
            <div className="flex flex-row items-center justify-between pl-4">
              <span className="w-32 text-left">COBERTURA 1.</span>
              <span className="flex-1 text-left">
                <a href={`#cobertura1`}
                  className="cursor-pointer text-purple-500 underline" >
                  ASISTENCIA MÉDICA IN SITU
                </a>
              </span>
              {/* <span className="w-12 text-right">23</span> */}
            </div>
            <div className="flex flex-row items-center justify-between pl-4">
              <span className="w-32 text-left">COBERTURA 2.</span>
              <span className="flex-1 text-left">
                <a href={`#cobertura2`}
                  className="cursor-pointer text-purple-500 underline" >
                  PRUEBAS DE LABORATORIO CLÍNICO
                </a>
              </span>
              {/* <span className="w-12 text-right">25</span> */}
            </div>
            <div className="flex flex-row items-center justify-between pl-4">
              <span className="w-32 text-left">COBERTURA 3.</span>
              <span className="flex-1 text-left">
                <a href={`#cobertura3`}
                  className="cursor-pointer text-purple-500 underline" >
                  ENTREGA DE MEDICAMENTOS
                </a>
              </span>
              {/* <span className="w-12 text-right">26</span> */}
            </div>
            <div className="flex flex-row items-center justify-between pl-4">
              <span className="w-32 text-left">COBERTURA 4.</span>
              <span className="flex-1 text-left">
                <a href={`#cobertura4`}
                  className="cursor-pointer text-purple-500 underline" >
                  TRASLADOS
                </a>
              </span>
              {/* <span className="w-12 text-right">27</span> */}
            </div>
            <div className="flex flex-row items-center justify-between pl-4">
              <span className="w-32 text-left">COBERTURA 5.</span>
              <span className="flex-1 text-left">
                <a href={`#cobertura5`}
                  className="cursor-pointer text-purple-500 underline" >
                  COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD
                </a>
              </span>
              {/* <span className="w-12 text-right">27</span> */}
            </div>
            <div className="flex flex-row items-center justify-between font-bold pt-3">
              <span className="w-auto pr-1 text-left">SECCIÓN II: </span>
              <span className="flex-1 text-left">EXCLUSIONES</span>
              {/* <span className="w-12 text-right">31</span> */}
            </div>
            <div className="flex flex-row items-center justify-between font-bold pl-4">
              <span className="w-8 text-left">I.</span>
              <span className="flex-1 text-left">
                <a href={`#exclusion1`}
                  className="cursor-pointer text-purple-500 underline" >
                  EXCLUSIONES APLICABLES A TODAS LAS COBERTURAS
                </a>
              </span>
              {/* <span className="w-12 text-right">31</span> */}
            </div>
            <div className="flex flex-row items-center justify-between pt-3">
              <span className="w-auto pr-1 text-left">ANEXO B – </span>
              <span className="flex-1 text-left">BOTÓN DE ASISTENCIA</span>
              {/* <span className="w-12 text-right">22</span> */}
            </div>
          </div>
        </div>

        <div className="mt-20 px-4">

          <p className="text-left my-4 text-lg">
            <b>1. {" "}  &nbsp;&nbsp;&nbsp; Artículo preliminar</b>
          </p>
          <p className="text-left my-4">
            <b>Su PLAN ASISTENSI</b>
          </p>
          <p className="text-justify my-4 ">
          Usted ha contratado un PLAN ASISTENSI. Un PLAN ASISTENSI es un seguro de asistencia sanitaria emitido por ASISTENCIA CLÍNICA UNIVERSITARIA DE NAVARRA, S.A. DE SEGUROS Y REASEGUROS, en adelante “<b>ACUNSA</b>”, cuyo sistema de prestaciones está coordinado por ASISTENSI.
          </p>
          <p className="text-justify my-4">
          El PLAN ASISTENSI tiene dos modalidades: <b>PLAN ASISTENSI</b> y <b>PLAN ASISTENSI PLUS</b>. La modalidad contratada por usted es la indicada en las Condiciones Particulares de su póliza.
          </p>
          <p className="text-justify my-4">
          Los PLANES ASISTENSI son seguros de asistencia sanitaria para su contratación por residentes en España que desean poner a disposición de sus <b>familiares o allegados residentes en la República Bolivariana de Venezuela</b> ciertos servicios y coberturas médicas.
          </p>
          <p className="text-left my-4">
            <b>El TOMADOR de este seguro no es asegurado ni beneficiario.</b>
          </p>
          <div className="px-4 text-justify bg-gray-400 border-2 border-gray-600">
            <p className="text-justify my-4">
            Durante la vigencia de la póliza las Condiciones Generales pueden modificarse para incorporar nuevos tratamientos y medios de diagnóstico, modificaciones legislativas o cualquier otro tipo de precisión sobre las coberturas. Cualquier modificación que implique una reducción de coberturas médicas deberá ser comunicada expresamente al TOMADOR, pudiendo éste oponerse a la renovación de la póliza.
            </p>
            <p className="text-justify my-4">
            La versión actualizada y en vigor de estas Condiciones Generales puede consultarse en
              <a
                  href={`https://www.asistensi.com/`}
                  className="ml-1 cursor-pointer text-purple-500 font-bold underline"
                  target="_blank"
                >
                  https://www.asistensi.com/
                </a>
            </p>
          </div>
          <p className="text-left mt-10 my-4">
            <b>Información sobre ACUNSA</b>
          </p>
          <p className="text-justify my-4">
          La actividad aseguradora que lleva a cabo ACUNSA está sometida al control y supervisión de la Dirección General de Seguros y Fondos de Pensiones dependiente del Ministerio de Economía y Empresa.
          </p>
          <p className="text-justify my-4">
          El presente contrato se encuentra sometido a la Ley de Contrato de Seguro 50/1980, de 8 de octubre (B.O.E. de 17 de Octubre), por lo dispuesto en la Ley 20/2015 de 14 de julio de Ordenación, Supervisión y Solvencia de las Entidades Aseguradoras y Reaseguradoras y su Reglamento de desarrollo, aprobado por el Real Decreto 1060/2015 de 20 de noviembre, así como por lo que se conviene en las presentes Condiciones Generales y en las Condiciones Particulares, sin que tengan validez las cláusulas limitativas de los derechos del TOMADOR y del ASEGURADO que no sean aceptadas por el TOMADOR. No requerirán la mencionada aceptación las meras transcripciones o referencias a preceptos legales o reglamentos imperativos.
          </p>
          <p className="text-left mt-10 my-4">
            <b>Derecho de desistimiento</b>
          </p>
          <p className="text-justify my-4">
          Conforme a lo previsto en artículo 10 de la Ley 22/2007, de 11 de julio sobre comercialización a distancia de servicios financieros destinados a los consumidores, el tomador dispone de un plazo de 14 días naturales para desistir del contrato, a contar desde la fecha de entrega de la póliza o desde el día en que el tomador reciba las condiciones contractuales y la información precontractual de la póliza, lo que ocurriese más tarde.
          </p>
          <p className="text-justify my-4">
          El derecho de desistimiento se tendrá que comunicar antes de que finalice el plazo por un procedimiento que permita dejar constancia de la notificación, en un soporte de papel o en otro soporte duradero, disponible y accesible al destinatario. El tomador podrá gestionar su derecho de desistimiento mediante su notificación a la dirección electrónica europa@asistensi.com. Una vez recibida la notificación de desistimiento, la cobertura del contrato de seguro quedará sin efecto.
          </p>
          <p className="text-justify my-4">
          El asegurador devolverá a la mayor brevedad y dentro de un plazo máximo de 30 días naturales desde la comunicación del desistimiento la prima satisfecha, salvo la parte de la misma correspondiente al periodo de cobertura transcurrido desde el inicio del seguro hasta el momento del desistimiento.
          </p>
          <p className="text-justify my-4">
          En caso de comunicación de un siniestro no se podrá ejercer el derecho de desistimiento.
          </p>
        </div>

        <div id="articulo2" className="mt-20 px-4">
          <p className="text-left my-4 text-lg">
            <b>2. {" "}  &nbsp;&nbsp;&nbsp; Definiciones</b>
          </p>
          <div className="my-2 ml-3">
            <ul className="my-2 space-y-3">
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.1.</span>
                <p className="flex-1 text-justify">
                  <b>ACCIDENTE:</b> suceso violento, súbito, externo y ajeno a la intencionalidad del BENEFICIARIO, que le cause a este último lesiones corporales. </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.2.</span>
                <p className="flex-1 text-justify">
                  <b>ÁMBITO TERRITORIAL:</b> la provisión de los servicios se limita al territorio de la República Bolivariana de Venezuela, en las siguientes ciudades: Caracas, Gran Caracas, Barcelona, Barquisimeto, Cagua, Charallave, Guacara, Guarenas, Guatire, La Victoria, Lechería, Los Teques, Maracaibo, San Francisco, Maracay, Pampatar, Porlamar, Puerto La Cruz, Puerto Ordáz, San Antonio de Los Altos, San Cristóbal, Turmero, Valencia y Vargas.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.3.</span>
                <p className="flex-1 text-justify">
                  <b>ASEGURADOR o ACUNSA:</b> Asistencia Clínica Universitaria de Navarra, S.A. de Seguros y Reaseguros.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.4.</span>
                <p className="flex-1 text-justify">
                  <b>ASISTENCIA MÉDICA IN SITU:</b> es la prestación del servicio de atención médica, que los médicos ofrecerán en el lugar donde se encuentre el BENEFICIARIO, mediante las siguientes modalidades de asistencia:
                  <ul className="list-disc my-2 space-y-2 ml-12">
                    <li>ATENCIÓN MÉDICA DOMICILIARIA.</li>
                    <li>ORIENTACIÓN MÉDICA TELEFÓNICA.</li>
                    <li>ORIENTACIÓN MÉDICA VIRTUAL.</li>
                  </ul>
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.5.</span>
                <p className="flex-1 text-justify">
                  <b>ASISTENSI:</b> Asistensi Global Insurance, Inc., que, por designación del ASEGURADOR, coordina la prestación de la asistencia sanitaria prevista en esta PÓLIZA con proveedores, subsidiarias y compañías afiliadas.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.6.</span>
                <p className="flex-1 text-justify">
                  <b>ATENCIÓN MÉDICA DOMICILIARIA:</b> atención médica de carácter individual en el lugar donde se encuentre el BENEFICIARIO, dentro del ÁMBITO TERRITORIAL especificado en el presente documento y con el alcance y limitaciones previstos en el Anexo A de estas Condiciones Generales.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.7.</span>
                <p className="flex-1 text-justify">
                  <b>BENEFICIARIO o ASEGURADO:</b> persona física que, por su inclusión en la póliza por parte del Tomador, tiene derecho a recibir una prestación sanitaria en caso de producirse un siniestro cubierto por la PÓLIZA.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.8.</span>
                <p className="flex-1 text-justify">
                  <b>BUEN ESTADO DE SALUD:</b> tener un estado de bienestar o equilibrio en el que no se presente ninguna alteración de la salud que origine reducción de la capacidad funcional ni que pueda requerir tratamiento médico y/o intervención quirúrgica, y no padecer o haber padecido ninguna de las siguientes enfermedades:
                  <ul className="list-disc my-2 space-y-2 ml-12">
                    <li>Alteraciones de salud que originen reducción de la capacidad funcional moderada o severa, incluyendo personas postradas en la cama, Alzheimer, esquizofrenia o demencia .</li>

                    <li>Enfermedades cardiovasculares, incluyendo cardiopatía isquémica, angina de pecho, infarto al miocardio, insuficiencia cardíaca, insuficiencia valvular aórtica y otras válvulas cardíacas, Accidente Cerebrovascular (ACV), ataques de isquemia cerebral, aneurisma, angioplastia o colocación de stent, marcapasos.</li>

                    <li>Enfermedades autoinmunes, incluyendo EBPOC (Enfermedad Broncopulmonar Obstructiva Crónica), insuficiencia renal crónica, lupus, esclerosis múltiple, artritis reumatoide, esclerodermia.</li>

                    <li>Hipertensión arterial sin tratamiento médico y no controlada.</li>

                    <li>Diabetes mellitus sin tratamiento médico y no controlada, lo cual se entenderá en todo caso para aquellas personas con un nivel de hemoglobina glicosilada superior o igual al 7%. Las personas que padezcan diabetes mellitus y no puedan demostrar que su nivel de hemoglobina glicosilada se encuentra en un nivel igual o inferior al 7% con un análisis clínico de un laboratorio médico reconocido de menos de 90 días desde su medición y enviado a ASISTENSI en los primeros quince (15) días desde la contratación del PLAN se considerarán que no gozan de BUEN ESTADO DE SALUD.</li>

                    <li>Cáncer.</li>
                  </ul>
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.9.</span>
                <p className="flex-1 text-justify">
                  <b>CENTRAL DE ALARMA TELEFÓNICA:</b> unidad de atención que funciona las veinticuatro (24) horas del día durante todo el año, para coordinar la prestación eficiente del servicio, centralizando y validando las llamadas telefónicas. Los números de contacto de la Central de Alarma Telefónica son +58 (212) 822 12 60 y +58 (800) 836.36.37 (Venezuela); +1 (305) 455 88 11(Estados Unidos); y +34 (911) 238 276(España).
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.10.</span>
                <p className="flex-1 text-justify">
                  <b>CENTRO DE SALUD:</b> institución hospitalaria privada, clínica privada u hospital privado para el cuidado de la salud de pacientes.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.11.</span>
                <p className="flex-1 text-justify">
                  <b>COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD:</b> Cobertura de gastos médicos prevista en el Anexo A de esta Póliza por un importe máximo de DIEZ MIL DÓLARES AMERICANOS (USD. 10.000), dependiendo de si el Tomador contrata el PLAN ASISTENSI o el PLAN ASISTENSI PLUS, pagaderos en Bolívares Digitales al CENTRO DE SALUD, calculados con base en el FACTOR DE TASA DE CAMBIO calculado por el ASEGURADOR. <span className="underline font-bold">El BENEFICIARIO sólo tendrá derecho a esta cobertura si consta como contratada en las Condiciones Particulares</span>.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.12.</span>
                <p className="flex-1 text-justify">
                  <b>CONDICIONES DE ADMISIBILIDAD:</b> son los siguientes requisitos que el TOMADOR declaró en el CUESTIONARIO DE SALUD que son cumplidos por todos los BENEFICIARIOS <span className="underline">para los que se haya contratado la COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD</span> y cuyo cumplimiento efectivo y real es condición para que cada BENEFICIARIO pueda disfrutar de dicha cobertura:
                  <ul className="list-disc my-2 space-y-2 ml-10">
                    <li>Edad comprendida entre 0 y 120 años, inclusive. A los fines de los límites de edad establecidos, se considerará asegurada la persona hasta el vencimiento del año- póliza en el cual el BENEFICIARIO alcance la edad máxima fijada en cada caso.</li>
                    <li>Ser familiar o tener una relación de afinidad con el del TOMADOR del PLAN.</li>
                    <li>Gozar de BUEN ESTADO DE SALUD al momento de la contratación.</li>
                    <li>No ser consumidores de ningún tipo de sustancias estupefacientes o psicotrópicas, salvo prescripción médica.</li>
                    <li>Tener residencia habitual dentro del ÁMBITO TERRITORIAL.</li>
                    <li>Ser titulares de una MEMBRESÍA ASISTENSI vigente.</li>
                  </ul>
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.13.</span>
                <p className="flex-1 text-justify">
                  <b>CONSULTA:</b> orientación médica que solicita el BENEFICIARIO
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.14.</span>
                <p className="flex-1 text-justify">
                  <b>CONTRATACIÓN:</b> procedimiento a llevar a cabo por el TOMADOR para la inclusión del BENEFICIARIO o BENEFICIARIOS en los PLANES ASISTENSI. Respecto a la contratación realizada por el TOMADOR, cada uno de los BENEFICIARIOS sólo podrá acceder a un sólo PLAN. <b>En ningún caso se podrán contratar dos (2) planes a la vez para los BENEFICIARIOS ya sea por el mismo TOMADOR o por distintos TOMADORES</b>.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.15.</span>
                <p className="flex-1 text-justify">
                  <b>CUESTIONARIO DE SALUD:</b> es la declaración realizada y firmada digitalmente por el TOMADOR durante el proceso de CONTRATACIÓN que permite al ASEGURADOR valorar el riesgo que va a cubrir. El TOMADOR tiene la obligación de cumplimentarlo de forma exacta y veraz. No hacerlo podría dejar sin cobertura algunos servicios y/o ser motivo de rescisión o resolución de la PÓLIZA.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.16.</span>
                <p className="flex-1 text-justify">
                  <b>EMERGENCIA MÉDICA:</b> condición de carácter súbito y repentino, manifestada por síntomas agudos y severos que requieren de una atención inmediata, y que en caso de no recibir la atención médica necesaria y oportuna pueda poner en peligro la vida o la función de un órgano del BENEFICIARIO. Se entienden también comprendidas en el presente término aquellas situaciones de urgencia que no pongan en evidente peligro la vida ni ocasionen consecuencias graves para las personas, por lo que no amerita atención médica de manera súbita.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.17.</span>
                <p className="flex-1 text-justify">
                  <b>ENFERMEDAD:</b> alteración de la salud que resulte de la acción de agentes morbosos de origen interno o externo con relación al organismo, que origine reducción de su capacidad funcional y que requiera tratamiento médico y/o intervención quirúrgica.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.18.</span>
                <p className="flex-1 text-justify">
                  <b>ENFERMEDAD PREEXISTENTE:</b> Enfermedad o lesión que pueda comprobarse ha sido adquirida con anterioridad a la fecha de inicio de la vigencia de la Póliza, y sea conocida por el Asegurador o el BENEFICIARIO.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.19.</span>
                <p className="flex-1 text-justify">
                  <b>ENFERMEDAD CONGÉNITA:</b> se considera enfermedad congénita a todas aquellas condiciones, lesiones o enfermedades que hayan sido manifestadas y reconocidas al momento de su nacimiento, o bien hayan sido descubiertas durante el transcurso de su vida, como consecuencia de factores hereditarios o adquiridos durante la gestación hasta el momento del parto. Se considerará enfermedad preexistente si es conocida por ASISTENSI o el BENEFICIARIO a la fecha de inicio de la vigencia del contrato o de la inclusión del BENEFICIARIO en la Póliza.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.20.</span>
                <p className="flex-1 text-justify">
                  <b>ENTREGA DE MEDICAMENTOS:</b> servicios de entrega de INVENTARIO DOMICILIARIO y de INVENTARIO CRÍTICO.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.21.</span>
                <p className="flex-1 text-justify">
                  <b>EQUIPO DE SALUD:</b> lo integran profesionales médicos y paramédicos debidamente certificados y autorizados para el ejercicio profesional amparado en la Ley del Ejercicio de la Medicina, calificados en la atención de EMERGENCIAS MÉDICAS de tipo prehospitalarias.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.22.</span>
                <p className="flex-1 text-justify">
                  <b>FACTOR DE TASA DE CAMBIO:</b> Resultado de la fórmula utilizada para calcular el tipo de cambio que se aplicará al importe de la cobertura a pagar al CENTRO DE SALUD. El resultado dependerá del valor de la tasa oficial de cambio de divisas de la República Bolivariana de Venezuela y el promedio de cambio utilizado por el sistema de CENTROS DE SALUD. <span className="underline font-bold">El ASEGURADOR se reserva el derecho de establecer dicho factor como resultado de sus cálculos propios</span>.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.23.</span>
                <p className="flex-1 text-justify">
                  <b>HORARIO DE SERVICIO:</b> los horarios de servicio serán los indicados a continuación:
                  <ul className="list-disc my-2 space-y-2 ml-10">
                    <li>
                      <b>Orientación Médica Telefónica, Atención Médica Domiciliaria, Traslados, Cobertura de Salud para Emergencias Médicas en Centros de Salud: </b> las veinticuatro (24) horas del día y los trescientos sesenta y cinco (365) días del año de conformidad con lo dispuesto en el presente documento.
                    </li>
                    <li>
                      <b>Orientación Médica Virtual: </b> de 7:30am a 11:00pm (GMT -4), en horario de la República Bolivariana de Venezuela, los trescientos sesenta y cinco (365) días del año.
                    </li>
                    <li>
                      <b>Pruebas de Laboratorios Clínicos: </b> En función del horario de atención del proveedor local elegido por el BENEFICIARIO para la prestación del servicio.
                    </li>
                  </ul>
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.24.</span>
                <p className="flex-1 text-justify">
                  <b>INVENTARIO CRÍTICO:</b> listado de medicamentos, insumos, materiales y equipos médicos disponibles que pueden ser requeridos por un BENEFICIARIO en el ámbito del ingreso/hospitalización en un CENTRO DE SALUD durante las primeras setenta y dos (72) horas de una PATOLOGÍA / EVENTO que ponga en riesgo la salud y/o vida.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.25.</span>
                <p className="flex-1 text-justify">
                  <b>INVENTARIO DOMICILIARIO:</b> listado de medicamentos e insumos médicos disponibles que pueden ser requeridos por un BENEFICIARIO como consecuencia de una PATOLOGÍA / EVENTO diagnosticado por un médico profesional en el ámbito de una consulta médica privada o ATENCIÓN MÉDICA IN SITU.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.26.</span>
                <p className="flex-1 text-justify">
                  <b>MÉDICO:</b> Profesional de la medicina titulado e inscrito en el Ministerio con competencia en materia de salud o en la institución que legalmente corresponda, para ejercer la profesión médica en el país donde presta sus servicios.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.27.</span>
                <p className="flex-1 text-justify">
                  <b>MÉDICAMENTE NECESARIO:</b> Conjunto de medidas o procedimientos ordenados y suministrados por un médico o centro de salud, que se ponen en práctica para el tratamiento, curación o alivio de una enfermedad o lesión, bajo las siguientes características:
                  <ul className="list-decimal my-2 space-y-2 ml-12">
                    <li className="pl-3">Que sea apropiado para el diagnóstico y tratamiento de la enfermedad o lesión del BENEFICIARIO..</li>
                    <li className="pl-3">Que sea congruente con las normas profesionales aceptadas en la práctica de la medicina en la República Bolivariana de Venezuela y por la Federación Médica Venezolana.</li>
                    <li className="pl-3">Que el nivel de servicio o suministro sea idóneo y pueda ser proporcionado sin riesgo para el BENEFICIARIO.</li>
                    <li className="pl-3">Que no sea primordialmente para el confort o la conveniencia personal del BENEFICIARIO, de su familia o de su Médico.</li>
                  </ul>
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.28.</span>
                <p className="flex-1 text-justify">
                  <b>MEMBRESÍA ASISTENSI:</b> es la condición de miembro de una comunidad de usuarios registrados en las plataformas digitales de ASISTENSI para tener acceso exclusivo a diversos beneficios y la contratación de planes y servicios exclusivos.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.29.</span>
                <p className="flex-1 text-justify">
                  <b>ORIENTACIÓN MÉDICA TELEFÓNICA:</b> son aquellas recomendaciones médicas telefónicas al BENEFICIARIO, al comunicarse con la CENTRAL DE ALARMA TELEFÓNICA. El alcance de las recomendaciones dependerá de la condición (patología) indicada por el BENEFICIARIO, para las cuales no se requiere de una UNIDAD MÉDICA, todo ello con las limitaciones previstas en la PÓLIZA y en particular en el Anexo A de estas Condiciones Generales.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.30.</span>
                <p className="flex-1 text-justify">
                  <b>ORIENTACIÓN MÉDICA VIRTUAL:</b> asesoramiento a distancia a través de videoconferencia, o mediante acceso disponible a través de la aplicación (App) móvil de ASISTENSI. El alcance de la asesoría dependerá de la condición (patología) indicada por el BENEFICIARIO, en los cuales no se requiere de una UNIDAD MÉDICA, todo ello con las limitaciones previstas en la PÓLIZA y en particular en el Anexo A de estas Condiciones Generales.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.31.</span>
                <p className="flex-1 text-justify">
                  <b>PATOLOGÍA / EVENTO:</b> accidente, enfermedad y/o EMERGENCIA MÉDICA que comprometan la salud y/o vida del BENEFICIARIO.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.32.</span>
                <p className="flex-1 text-justify">
                  <b>PERSONAL MÉDICO Y TÉCNICO:</b> profesionales médicos debidamente autorizados por la Ley del Ejercicio de la Medicina; paramédicos y personal de soporte calificado para la atención médica de emergencias; así como los conductores de las UNIDADES MÉDICAS. Ninguno de los profesionales médicos antes mencionados, será considerado, en cualquier caso, como médico tratante.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.33.</span>
                <p className="flex-1 text-justify">
                  <b>PLAN ASISTENSI, PLAN O PÓLIZA:</b> es el presente seguro de asistencia sanitaria que garantiza atención al BENEFICIARIO ante una EMERGENCIA MÉDICA, a través de la prestación de servicios de ASISTENCIA MÉDICA IN SITU, TRASLADOS, ENTREGA DE MEDICAMENTOS y PRUEBAS DE LABORATORIOS CLÍNICOS, y, <span className="underline">en caso de que haya sido contratada y así conste en las Condiciones Particulares</span>, la COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD en la República Bolivariana de Venezuela. Un PLAN ASISTENSI tiene dos modalidades con diferentes coberturas: PLAN ASISTENSI y PLAN ASISTENSI PLUS.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.34.</span>
                <p className="flex-1 text-justify">
                  <b>PRÓTESIS:</b> Dispositivo o aparato diseñado para reemplazar una parte faltante del cuerpo o para hacer que una parte del cuerpo trabaje mejor.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.35.</span>
                <p className="flex-1 text-justify">
                  <b>PRUEBAS DE LABORATORIOS CLÍNICOS:</b> son los análisis o pruebas clínicas recogidos en el Anexo A de estas Condiciones Generales. El BENEFICIARIO podrá acudir a realizarse un análisis o prueba clínica siempre y cuando cuente con receta médica y autorización previa de ASISTENSI. <span className="underline font-bold">El BENEFICIARIO tendrá derecho a 2 (dos) análisis por año independientemente de la modalidad de PLAN contratada.</span>.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.36.</span>
                <p className="flex-1 text-justify">
                  <b>SISTEMA COORDINADO DE PROVEEDORES:</b> empresas, médicos, paramédicos, enfermeras y CENTROS DE SALUD que prestarán los servicios de asistencia sanitaria previstos en esta PÓLIZA. Su composición variará en función de los acuerdos que en cada momento el ASEGURADOR, por sí mismo o a través de terceros, celebre con los proveedores legalmente autorizados para prestar los servicios de asistencia sanitaria previstos en esta PÓLIZA. Dichos acuerdos con proveedores pueden incluir contratos de seguro suscritos por ASISTENSI, por sí mismo o a través de terceros, que den derecho a los BENEFICIARIOS a recibir en el ÁMBITO TERRITORIAL los servicios previstos en esta PÓLIZA.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.37.</span>
                <p className="flex-1 text-justify">
                  <b>TOMADOR:</b> persona física o jurídica que contrata el PLAN para el BENEFICIARIO. El TOMADOR será el responsable de la inclusión de los BENEFICIARIOS en el PLAN o PLANES, del pago correspondiente por la CONTRATACIÓN del PLAN o PLANES y del cumplimiento de este contrato.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.38.</span>
                <p className="flex-1 text-justify">
                  <b>TRASLADOS:</b> incluye, con las limitaciones previstas en la PÓLIZA y en particular en el Anexo A de estas Condiciones Generales, las siguientes modalidades de cobertura:
                  <ul className="list-disc my-2 space-y-2 ml-10">
                    <li>TRASLADO DE RETORNO o ALTA MÉDICA.</li>
                    <li>TRASLADO DE TERAPIA INTENSIVA.</li>
                    <li>TRASLADO A OTRO CENTRO DE SALUD o INTERINSTITUCIONAL.</li>
                    <li>TRASLADO EN AMBULANCIAS.</li>
                    <li>TRASLADO PROGRAMADO.</li>
                  </ul>
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.39.</span>
                <p className="flex-1 text-justify">
                  <b>TRASLADO A OTRO CENTRO DE SALUD o INTERINSTITUCIONAL (urbano):</b> después que el BENEFICIARIO haya ingresado en un CENTRO DE SALUD como consecuencia de una emergencia médica, este podrá solicitar, con al menos doce (12) horas de antelación, una unidad de traslado a otro CENTRO DE SALUD dentro de la misma ciudad/población donde se encuentra el CENTRO DE SALUD inicial si el personal médico lo determina necesario.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.40.</span>
                <p className="flex-1 text-justify">
                  <b>TRASLADO DE RETORNO o ALTA MÉDICA (urbano):</b> después que el BENEFICIARIO haya recibido el alta médica en el CENTRO DE SALUD, éste podrá solicitar con al menos doce (12) horas de antelación, una unidad de traslado de retorno a su domicilio dentro de la misma ciudad/población de ocurrencia del hecho si el personal médico lo determina necesario.
                  </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.41.</span>
                <p className="flex-1 text-justify">
                  <b>TRASLADO DE TERAPIA INTENSIVA:</b> coordinación y movilización de personal médico y técnico y de una UNIDAD MÉDICA especializado para la atención de soporte de vida avanzado (terapia intensiva) y con equipos de monitorización de signos vitales, desfibrilación, aspiración, ventilación mecánica y/o incubadora, para la prestación del servicio en la atención de pacientes que requieren ser movilizados entre dos servicios distintos de terapia intensiva de CENTROS DE SALUD, en los cuales se requiera realizar el traslado del BENEFICIARIO, siempre que se trate de beneficiarios adultos, pediátricos y/o neonatales, en un horario determinado y/o con características especiales a un destino predeterminado si el personal médico lo determina necesario. Deberá ser solicitado con al menos doce (12) horas de antelación al momento del TRASLADO DE TERAPIA INTENSIVA.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.42.</span>
                <p className="flex-1 text-justify">
                  <b>TRASLADO EN AMBULANCIAS:</b> coordinación y movilización de personal médico y técnico y de una UNIDAD MÉDICA, para la prestación del servicio en la atención de accidentes, enfermedad o emergencias en los cuales se requiera realizar el traslado de la persona a un CENTRO DE SALUD dentro de los límites de una ciudad o población (desde-hasta) de manera inmediata.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.43.</span>
                <p className="flex-1 text-justify">
                  <b>TRASLADO PROGRAMADO:</b> coordinación y movilización de personal médico y técnico y de una UNIDAD MÉDICA, para la prestación del servicio en la atención de accidentes, y/o enfermedad en los cuales se requiera realizar el traslado de la persona en un horario determinado y/o con características especiales a un destino predeterminado si el personal médico lo determina necesario.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.44.</span>
                <p className="flex-1 text-justify">
                  <b>PROCEDIMIENTO EXPERIMENTAL O INVESTIGATIVO:</b> Tratamiento médico, intervención quirúrgica, suministro, medicamento, procedimiento médico u hospitalización que:
                  <ul className="list-decimal my-2 space-y-2 ml-10">
                    <li>No haya sido aceptado como seguro, efectivo y apropiado para el tratamiento médico de enfermedades por el consenso de las organizaciones profesionales que están reconocidas por la comunidad médica internacional; o</li>
                    <li>Su uso esté restringido a objetivos clínicos disciplinados que posean valor o beneficio para propósitos clínicos de la disciplina y estudios científicos; o</li>
                    <li>No se haya probado de manera objetiva que posea valor o beneficio terapéutico; o</li>
                    <li>Esté bajo estudio, investigación, en un período de prueba o en cualquier fase de un experimento o ensayo clínico.</li>
                  </ul>
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.45.</span>
                <p className="flex-1 text-justify">
                  <b>TRATAMIENTO MÉDICO:</b> Conjunto de medidas realizadas u ordenadas por un médico que se ponen en práctica para la curación o alivio de una enfermedad o lesión, incluyendo medicamentos prescritos, insumos o prótesis.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.46.</span>
                <p className="flex-1 text-justify">
                  <b>UNIDAD MÉDICA:</b> vehículos dotados con equipos destinados a la atención de EMERGENCIAS MÉDICAS, acondicionadas con insumos e instrumentos para la estabilización de la condición física que presente el BENEFICIARIO, pueden ser de tres (3) tipos:
                  <ul className="my-2 space-y-2 ml-5">
                    <li className="flex flex-row ">
                      <span className="pr-5 ">a.</span>
                      <p className="flex-1 text-justify">
                        <b>Avance: </b>vehículo de transporte con dotación médica para atender emergencia no grave, que no ameriten traslado del paciente y su tripulación consta de personal paramédico-conductor y médico.
                      </p>
                    </li>
                    <li className="flex flex-row ">
                      <span className="pr-5 ">b.</span>
                      <p className="flex-1 text-justify">
                        <b>Ambulancia: </b>vehículo de transporte destinado para la atención de emergencia médica en las que exista peligro inminente para la vida del paciente, equipadas con todo el soporte necesario para prestar este tipo de atención y su tripulación consta de personal paramédico y médico.
                      </p>
                    </li>
                    <li className="flex flex-row ">
                      <span className="pr-5 ">b.</span>
                      <p className="flex-1 text-justify">
                        <b>Moto-Ambulancia: </b>Motocicleta adaptada con sistemas de identificación, luces, sirena y equipamiento de emergencia para el traslado de personal paramédico y médico a los fines de realizar la atención de EMERGENCIA MÉDICA de carácter no grave. En caso de requerir eventual traslado del BENEFICIARIO a un CENTRO DE SALUD escogido por éste, deberá ser realizada por una UNIDAD MÉDICA, de tipo Ambulancia o Avance.
                      </p>
                    </li>
                  </ul>
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="font-bold pr-5 ">2.47.</span>
                <p className="flex-1 text-justify">
                  <b>UNIDOSIS:</b> Dosis concreta y unitaria de un medicamento que un BENEFICIARIO recibe en el momento de la evaluación médica para mejorar, tratar o eliminar un síntoma determinado.
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div id="articulo3" className="mt-10 px-4">
          <p className="text-left my-4 text-lg">
            <b>3. {" "}  &nbsp;&nbsp;&nbsp; Objeto del seguro</b>
          </p>
          <div className="my-2 ml-3">
            <p className="text-justify my-4 ">
            <b>En caso de EMERGENCIA MÉDICA</b>, dentro de los <b>límites</b> y condiciones estipulados por la PÓLIZA y una vez realizado el pago de la prima, el ASEGURADOR, ya sea por sí mismo o a través de terceros, y bajo la coordinación de ASISTENSI, pondrá a disposición de los BENEFICIARIOS para su uso <b>dentro del ÁMBITO TERRITORIAL</b> los servicios de asistencia sanitaria <b>descritos en el Anexo A de estas Condiciones Generales</b> que incluyen:
            </p>
            <ul className="list-decimal my-2 space-y-2 ml-10">
              <li className="pl-3">ASISTENCIA MÉDICA IN SITU.</li>
              <li className="pl-3">PRUEBAS DE LABORATORIO CLÍNICO.</li>
              <li className="pl-3">ENTREGA DE MEDICAMENTOS.</li>
              <li className="pl-3">TRASLADOS.</li>
              <li className="pl-3">COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD, pero únicamente si esta cobertura ha sido contratada por el TOMADOR y así consta en las Condiciones Particulares.</li>
            </ul>
            <p className="text-justify my-4 ">
            La prestación de los servicios de asistencia sanitaria objeto de este seguro se realizará <b>previa solicitud</b> por el BENEFICIARIO a ASISTENSI, que actuará en todo momento como coordinador del SISTEMA COORDINADO DE PROVEEDORES y, en su caso, <b>autorizará</b> la prestación de los servicios de asistencia sanitaria al BENEFICIARIO, todo ello según lo previsto en el Artículo 7 (Procedimiento para solicitar la prestación de servicios) más abajo.
            </p>
          </div>
        </div>

        <div id="articulo4" className="mt-10 px-4">
          <p className="text-left my-4 text-lg">
            <b>4. {" "}  &nbsp;&nbsp;&nbsp; Límites</b>
          </p>
          <div className="my-2 ml-3">
            <p className="text-justify my-4 ">
            Las coberturas de asistencia sanitaria cubiertos por esta PÓLIZA están <span className="underline">sujetos a los límites establecidos en las Condiciones Particulares</span>. </p>
          </div>
        </div>

        <div id="articulo5" className="mt-10 px-4">
          <p className="text-left my-4 text-lg">
            <b>5. {" "}  &nbsp;&nbsp;&nbsp; Periodos de carencia (o plazos de espera)</b>
          </p>
          <div className="my-2 ml-3">
            <p className="text-justify my-4 ">
            <b>El BENEFICIARIO no tendrá acceso a la asistencia prevista en esta PÓLIZA hasta que transcurran los siguientes periodos de tiempo desde el pago de la prima:</b>
            </p>
            <ul className="my-2 space-y-3 ml-5 font-bold">
              <li className="flex flex-row ">
                <span className="pr-5 w-8">I.</span>
                <p className="flex-1 text-justify">
                Cobertura de Orientación Médica Telefónica 24/7: veinticuatro (24) horas después del pago de la prima.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-5 w-8">II.</span>
                <p className="flex-1 text-justify">
                Cobertura de Orientación Médica Virtual (uso del APP para orientación por video o chat médico): veinticuatro (24) horas después del pago de la prima. </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-5 w-8">III.</span>
                <p className="flex-1 text-justify">
                Cobertura de Atención Médica Domiciliaria: quince (15) días continuos después del pago de la prima.
                </p>
              </li>
              <li className="flex flex-row ">
                  <span className="pr-5 w-8">IV.</span>
                  <p className="flex-1 text-justify">
                  Pruebas de Laboratorios Clínicos: quince (15) días continuos después del pago de la prima.
                  </p>
              </li>
              <li className="flex flex-row ">
                  <span className="pr-5 w-8">V.</span>
                  <p className="flex-1 text-justify">
                  Servicio de Traslados: quince (15) días continuos después del pago de la prima.
                  </p>
              </li>
              <li className="flex flex-row ">
                  <span className="pr-5 w-8">VI.</span>
                  <p className="flex-1 text-justify">
                  Servicio de Entrega de Medicamentos: quince (15) días continuos después del pago de la prima.
                  </p>
              </li>
              <li className="flex flex-row ">
                  <span className="pr-5 w-8">VII.</span>
                  <p className="flex-1 text-justify">
                  Cobertura de Salud para Emergencias Médicas en Centros de salud: aplicarán los siguientes plazos de espera:
                  <ul className="my-2 space-y-3 ml-5 font-bold">
                  <li className="flex flex-row ">
                    <span className="pr-5 w-8">a.</span>
                    <p className="flex-1 text-justify">
                    quince (15) días continuos después del pago de la prima para aquellos accidentes amparados por la PÓLIZA y para las siguientes enfermedades infecciosas agudas que generen una emergencia médica: fiebre reumática, apendicitis, bronquitis, gastroenteritis, abscesos (intraabdominales, intratorácicos e intracraneales), adenoiditis, vértigo o laberintitis, faringo – amigdalitis (sola o combinada), otitis, trastornos de la laringe, infección respiratoria baja, dengue hemorrágico, malaria, meningo-encefalitis, neumonía y pielonefritis, así como los gastos ocasionados por las siguientes enfermedades virales que generen una emergencia médica: parotiditis, rubéola, sarampión y varicela.
                    </p>
                  </li>
                  <li className="flex flex-row ">
                    <span className="pr-5 w-8">b.</span>
                    <p className="flex-1 text-justify"> Se prevé un periodo de espera de tres (3) meses continuos después del pago de la prima, para cualquier emergencia médica que no esté expresamente excluida de la cobertura o listada en el apartado a anterior.</p>
                  </li>
                  <li className="flex flex-row ">
                    <span className="pr-5 w-8">c.</span>
                    <p className="flex-1 text-justify">
                    Se prevé un periodo de espera de seis (6) meses continuos después del pago, para aquellos BENEFICIARIOS que sufran de hipertensión arterial y se les haya otorgado cobertura de emergencias médicas, independientemente de lo expresado en los apartados b y c anteriores.
                    </p>
                  </li>
                </ul>
                </p>


              </li>
            </ul>

          </div>
        </div>

        <div id="articulo6" className="mt-10 px-4">
          <p className="text-left my-4 text-lg">
            <b>6. {" "}  &nbsp;&nbsp;&nbsp; Riesgos excluidos</b>
          </p>
          <div className="my-2 ml-3">
            <p className="text-justify my-4 ">
            <span className="underline">Quedan excluidos de la cobertura de este seguro y no serán prestados los servicios ni cubiertos los gastos médicos indicados en el Anexo A de estas Condiciones Generales.</span>
            </p>
          </div>
        </div>

        <div id="articulo7" className="mt-10 px-4">
          <p className="text-left my-4 text-lg">
            <b>7. {" "}  &nbsp;&nbsp;&nbsp; Procedimiento para solicitar las prestaciones de la póliza </b>
          </p>
          <div className="my-2 ml-3">
            <ul className="my-2 space-y-2 ml-5">
              <li className="flex flex-row ">
                <span className="pr-5 ">7.1.</span>
                <p className="flex-1 text-justify">
                  <span className="underline">Solicitud de servicio.</span> En caso de que se produzca una EMERGENCIA MÉDICA, el BENEFICIARIO deberá contactar con ASISTENSI a través de cualquiera de los siguientes medios:
                  <ul className="list-disc my-2 space-y-2 ml-5">
                    <li>El aplicativo móvil disponible en las tiendas de APP: iOS y Android llamado “ASISTENSI”, indicando su número de identificación (cédula de identidad o pasaporte), su nombre, apellido y motivo de la llamada; o</li>

                    <li>El número de atención telefónica de CENTRAL DE ALARMA TELEFÓNICA indicando su número de identificación (cédula de identidad o pasaporte), su nombre, apellido y motivo de la llamada.</li>
                  </ul>
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-5 ">7.2.</span>
                <p className="flex-1 text-justify">
                  <span className="underline">ASISTENCIA MÉDICA IN SITU.</span> Recibida su solicitud, ASISTENSI coordinará la prestación de ASISTENCIA MÉDICA IN SITU al BENEFICIARIO mediante el servicio de ORIENTACIÓN MÉDICA TELEFÓNICA o de ORIENTACIÓN MÉDICA VIRTUAL.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-12 "></span>
                <p className="flex-1 text-justify">
                En caso de que el equipo de salud de ASISTENSI, en función de los síntomas y manifestaciones subjetivas del BENEFICIARIO o acompañantes, lo considere necesario, podrá autorizar la prestación del servicio de ATENCIÓN MÉDICA DOMICILIARIA y enviar un médico y paramédico al sitio en donde se encuentra el BENEFICIARIO para la realización de una consulta médica que puede derivar en el diagnóstico y tratamiento en el sitio, y/o recomendar y autorizar un TRASLADO EN AMBULANCIA para la derivación del caso a un nivel superior de prestación de servicios de salud.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-5 ">7.3.</span>
                <p className="flex-1 text-justify">
                  <span className="underline">TRASLADOS.</span> Recibida su solicitud, ASISTENSI coordinará la prestación del TRASLADO al BENEFICIARIO mediante el servicio de ORIENTACIÓN MÉDICA TELEFÓNICA o de ORIENTACIÓN MÉDICA VIRTUAL.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-5 ">7.4.</span>
                <p className="flex-1 text-justify">
                  <span className="underline">PRUEBAS DE LABORATORIO CLÍNICO.</span> Únicamente en caso de que el BENEFICIARIO cuente con autorización previa de ASISTENSI para la realización de alguna de las PRUEBAS DE LABORATORIO CLÍNICO del ANEXO A de estas Condiciones Generales, Dichas pruebas se realizarán en el proveedor local a elección del BENEFICIARIO, procediendo ASISTENSI al reembolso del importe incurrido por el BENEFICIARIO a tal fin.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-5 ">7.5.</span>
                <p className="flex-1 text-justify">
                  <span className="underline">ENTREGA DE MEDICAMENTOS.</span> Para hacer uso del servicio de ENTREGA DE MEDICAMENTOS el BENEFICIARIO deberá enviar el informe o receta médica a través de email o a través del aplicativo móvil disponible en las tiendas de APP: iOS y Android llamado “ASISTENSI”, y haber recibido una receta médica en una consulta médica privada o domiciliaria de ASISTENSI.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-12 "></span>
                <p className="flex-1 text-justify"> Una vez recibida la solicitud completa (solicitud con documentación del caso) se procederá a la validación del caso y confirmación de existencia del material, insumo y/o medicamento en los inventarios crítico y domiciliario, dando como resultado positivo el envío del inventario y/o provisión del servicio solicitado indicado en el ANEXO A de estas Condiciones Generales. </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-5 ">7.6.</span>
                <p className="flex-1 text-justify">
                  <span className="underline">COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD.</span> En caso de que esta cobertura haya sido contratada y así conste en las Condiciones Particulares, ASISTENSI, a través de personal médico calificado, determinará si el BENEFICIARIO se encuentra ante una EMERGENCIA MÉDICA que requiera su ingreso en un CENTRO DE SALUD por un riesgo cubierto por esta PÓLIZA.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-12 "></span>
                <p className="flex-1 text-justify">
                ASISTENSI trasladará al BENEFICIARIO a un CENTRO DE SALUD a los fines de brindarle atención médica por una EMERGENCIA MÉDICA que se encuentre cubierta por la Póliza y velará por que el BENEFICIARIO reciba atención inmediata.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-12 "></span>
                <p className="flex-1 text-justify">
                En caso de emergencias graves (accidentes, inconsciencia, dolor torácico sugestivo de infarto de miocardio, etc.) el BENEFICIARIO podrá trasladarse por sus propios medios a un CENTRO DE SALUD del SISTEMA COORDINADO DE PROVEEDORES, siendo obligatorio llamar a la CENTRAL DE ALARMA TELEFÓNICA para recibir un mejor servicio en el CENTRO DE SALUD y confirmar la pertenencia del CENTRO DE SALUD al SISTEMA COORDINADO DE PROVEEDORES.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-12 "></span>
                <p className="flex-1 text-justify">
                El BENEFICIARIO autoriza al médico tratante y al CENTRO DE SALUD a que comparta con el ASEGURADOR y/o ASISTENSI información acerca de su estado físico, historia clínica y demás circunstancias que originaron la atención, relevándolos de guardar el secreto médico. De igual manera, el BENEFICIARIO autoriza a ASISTENSI a solicitar y recibir cualquier tipo de información médica por parte del CENTRO DE SALUD y con ACUNSA sobre la atención del BENEFICIARIO con un fin legítimo de prestación de servicio de los PLANES ASISTENSI.
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div id="articulo8" className="mt-10 px-4">
          <p className="text-left my-4 text-lg">
            <b>8. {" "}  &nbsp;&nbsp;&nbsp; Bases, Pérdida de Derechos y Rescisión del contrato </b>
          </p>
          <div className="my-2 ml-3">
            <ul className="my-2 space-y-2 ml-5">
              <li className="flex flex-row ">
                <span className="pr-5 ">8.1.</span>
                <p className="flex-1 text-justify">
                Las declaraciones efectuadas en el CUESTIONARIO DE SALUD constituyen la base para la delimitación del riesgo y para la aceptación del presente contrato y forman parte integrante del mismo, junto con las Condiciones Particulares y Generales de la PÓLIZA.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-5 ">8.2.</span>
                <p className="flex-1 text-justify">
                El BENEFICIARIO no tendrá derecho a la asistencia sanitaria prevista en esta PÓLIZA por las causas previstas en la Ley y en todo caso:
                  <ul className="my-2 space-y-2 ml-5">
                    <li className="flex flex-row ">
                      <span className="pr-5 ">a.</span>
                      <p className="flex-1 text-justify">
                      Si la EMERGENCIA MÉDICA cuya cobertura se garantiza sobreviene antes de que se haya pagado la prima, de acuerdo con lo previsto en el &nbsp;<a href={`#articulo10`} className="cursor-pointer text-purple-500 font-bold underline">Artículo 10</a>.
                      </p>
                    </li>
                    <li className="flex flex-row ">
                      <span className="pr-5 ">b.</span>
                      <p className="flex-1 text-justify">
                      Cuando la EMERGENCIA MÉDICA haya sido causada por mala fe del ASEGURADO o TOMADOR.
                      </p>
                    </li>
                    <li className="flex flex-row ">
                      <span className="pr-5 ">c.</span>
                      <p className="flex-1 text-justify">
                      En caso de reserva o inexactitud al cumplimentar el CUESTIONARIO DE SALUD. En este caso, el ASEGURADOR podrá rescindir el Contrato, quedando las primas satisfechas a su favor, salvo que hubiese mediado dolo o culpa grave por parte del ASEGURADOR.
                      </p>
                    </li>
                  </ul>
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div id="articulo9" className="mt-10 px-4">
          <p className="text-left my-4 text-lg">
            <b>9. {" "}  &nbsp;&nbsp;&nbsp; Formalización y duración del seguro </b>
          </p>
          <div className="my-2 ml-3">
            <ul className="my-2 space-y-2 ml-5">
              <li className="flex flex-row ">
                <span className="pr-5 ">9.1.</span>
                <p className="flex-1 text-justify">
                La presente PÓLIZA se compone de las Condiciones Particulares y de las presentes Condiciones Generales.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-5 ">9.2.</span>
                <p className="flex-1 text-justify">
                El TOMADOR podrá reclamar al ASEGURADOR, en el plazo de un (1) mes desde la <span className="underline">entrega</span> de la PÓLIZA, que se subsanen las divergencias existentes entre ésta y la proposición del seguro o las cláusulas o artículos acordados. Transcurrido dicho plazo sin efectuar la reclamación se estará a lo dispuesto en la PÓLIZA.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-5 ">9.3.</span>
                <p className="flex-1 text-justify">
                La PÓLIZA no tendrán efecto hasta que el TOMADOR abone al ASEGURADOR la primera prima o fracción de la prima. Sin perjuicio de la fecha de efecto de la PÓLIZA, <b>tenga en cuenta los periodos de espera establecidos en el &nbsp;<a href={`#articulo5`} className="cursor-pointer text-purple-500 font-bold underline">Artículo 5</a>.</b>
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-5 ">9.4.</span>
                <p className="flex-1 text-justify">
                La duración de la PÓLIZA será la establecida en las Condiciones Particulares y a su vencimiento se prorrogará tácitamente por periodos anuales. No obstante, cualquiera de las partes podrá oponerse a la prórroga mediante notificación escrita a la otra, efectuada con antelación no inferior a dos (2) meses antes del vencimiento de la PÓLIZA, en el caso del ASEGURADOR, y de un (1) mes en el caso del TOMADOR.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-12 "></span>
                <p className="flex-1 text-justify">
                No obstante lo anterior, el TOMADOR podrá notificar de forma expresa y por escrito al ASEGURADOR, a través de ASISTENSI, su decisión de terminar la PÓLIZA, en cuyo caso serán de aplicación las siguientes reglas:
                  <ul className="my-2 space-y-2 ml-5">
                    <li className="flex flex-row ">
                      <span className="pr-5 ">a.</span>
                      <p className="flex-1 text-justify">
                      Cuando la modalidad de pago de la prima sea mensual, el TOMADOR deberá notificar su deseo de terminar la PÓLIZA respecto de todos o algún BENEFICIARIO con una antelación de al menos treinta (30) días antes de la finalización de la última mensualidad para la que desee que la PÓLIZA esté en vigor.
                      </p>
                    </li>
                    <li className="flex flex-row ">
                      <span className="pr-5 ">b.</span>
                      <p className="flex-1 text-justify">
                      Cuando la modalidad de pago de la prima sea anual, semestral o trimestral, la PÓLIZA terminará respecto de todos los BENEFICIARIOS o aquellos indicados por el TOMADOR al finalizar la mensualidad en curso cuando se realizó la notificación y se reintegrarán las cantidades correspondientes según las siguientes reglas:
                        <ul className="list-disc my-2 space-y-2 ml-10">
                          <li>Si la notificación del TOMADOR se realizó dentro de los catorce (14) días siguientes a la contratación de la PÓLIZA será de aplicación lo previsto en el párrafo 9.5.6 y en la legislación aplicable para el derecho de desistimiento.</li>

                          <li>Si la notificación del TOMADOR se realizó transcurridos catorce (14) días desde la contratación de la PÓLIZA y antes de transcurrir treinta (30) días, procederá el reembolso del 30% del importe satisfecho por el TOMADOR.</li>

                          <li>Si la notificación del TOMADOR se realizó transcurridos treinta (30) días o más desde la contratación de la PÓLIZA no procederá reembolso alguno.</li>
                        </ul>
                      </p>
                    </li>
                    <li className="flex flex-row ">
                      <span className="pr-5 ">c.</span>
                      <p className="flex-1 text-justify">
                      En caso de fallecimiento y/o cambio de residencia del BENEFICIARIO a una ciudad o país sin cobertura, el TOMADOR deberá comunicar expresamente esta circunstancia, dicha comunicación tendrá la consideración de una comunicación de cancelación y resultará de aplicación lo previsto en el apartado a. o b. anterior, según corresponda, con lo que se tomará en cuenta la fecha de la comunicación y no la fecha del fallecimiento o del cambio de residencia del BENEFICIARIO.
                      </p>
                    </li>
                  </ul>
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-5 ">9.5.</span>
                <p className="flex-1 text-justify">
                La PÓLIZA quedará igualmente resuelta y extinguida en los siguientes supuestos:
                </p>
              </li>
              <li className="flex ">
                <ul className="my-2 space-y-2 ml-7">
                  <li className="flex flex-row ">
                    <span className="pr-5 ">9.5.1.</span>
                    <p className="flex-1 text-justify">
                    Cuando el ASEGURADOR tenga conocimiento, por cualquier medio, de que la información suministrada por el TOMADOR o el BENEFICIARIO es falsa o inexacta u omitiere cualquier dato, que de haber sido conocido por el ASEGURADOR, éste no habría otorgado la cobertura o no lo habría hecho en las mismas condiciones. En estos casos no procederá reembolso alguno.
                    </p>
                  </li>
                  <li className="flex flex-row ">
                    <span className="pr-5 ">9.5.2.</span>
                    <p className="flex-1 text-justify">
                    Cuando el BENEFICIARIO utilice el servicio sin necesidad, de forma indiscriminada o excesiva, desvirtuando el objetivo del mismo. En este caso no procederá reembolso.
                    </p>
                  </li>
                  <li className="flex flex-row ">
                    <span className="pr-5 ">9.5.3.</span>
                    <p className="flex-1 text-justify">
                    Cuando el BENEFICIARIO y/o el TOMADOR tenga una conducta irrespetuosa o inadecuada con el personal del SISTEMA COORDINADO DE PROVEEDORES durante la prestación de los servicios. En este caso no procederá reembolso.
                    </p>
                  </li>
                  <li className="flex flex-row ">
                    <span className="pr-5 ">9.5.4.</span>
                    <p className="flex-1 text-justify">
                    Por la exclusión del BENEFICIARIO en caso de declaraciones de salud falsas, mala fe en su actuación o cualquier incumplimiento de las obligaciones del BENEFICIARIO y/o el TOMADOR. En este caso no procederá reembolso.
                    </p>
                  </li>
                  <li className="flex flex-row ">
                    <span className="pr-5 ">9.5.5.</span>
                    <p className="flex-1 text-justify">
                    Cuando el ASEGURADOR tenga conocimiento de que un BENEFICIARIO tenga dos (2) PLANES contratados a la vez, el ASEGURADOR podrá cancelar el segundo Plan ya que no está permitido tener dos (2) PLANES simultáneamente.
                    </p>
                  </li>
                  <li className="flex flex-row ">
                    <span className="pr-5 ">9.5.6.</span>
                    <p className="flex-1 text-justify">
                    Por ejercicio del derecho de desistimiento legalmente previsto sin indicación de los motivos y sin penalización alguna. El plazo para ejercer el derecho de desistimiento será de catorce (14) días, y empezará a correr desde el día de la formalización de la PÓLIZA o desde el día en que el TOMADOR reciba las condiciones contractuales y la información (pre)contractual de la póliza, lo que ocurriese más tarde.
                    </p>
                  </li>
                </ul>
              </li>
              <li className="flex flex-row ">
                <span className="pr-5 ">9.6.</span>
                <p className="flex-1 text-justify">
                La terminación o extinción de la PÓLIZA conforme a lo aquí previsto no es impedimento para que el TOMADOR pueda volver a realizar el proceso de CONTRATACIÓN.
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div id="articulo10" className="mt-10 px-4">
          <p className="text-left my-4 text-lg">
            <b>10. {" "}  &nbsp;&nbsp;&nbsp; Pago de Primas y efectos de su impago </b>
          </p>
          <div className="my-2 ml-3">
            <ul className="my-2 space-y-2 ml-5">
              <li className="flex flex-row ">
                <span className="pr-5 ">10.1.</span>
                <p className="flex-1 text-justify">
                Las primas o, en su caso, fracciones de prima, serán pagaderas en el momento y con la periodicidad que se indica en las Condiciones Particulares de la PÓLIZA. El medio de pago también se indicará en las Condiciones Particulares, aunque el TOMADOR podrá efectuar el abono de la prima por cualquier medio de pago habilitado por el ASEGURADOR.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-5 ">10.2.</span>
                <p className="flex-1 text-justify">
                En caso <b>de impago de la primera prima o primera fracción de la prima la PÓLIZA no tendrá efecto.</b>
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-5 ">10.3.</span>
                <p className="flex-1 text-justify">
                En caso de falta de pago de una de las primas siguientes a la primera o de una fracción de la prima siguiente a la primera, la cobertura de la PÓLIZA quedará en suspenso un (1) mes después de la fecha en que debió pagarse la prima o fracción de la prima. Si el ASEGURADOR no reclama el pago dentro de los seis (6) meses siguientes al vencimiento de la prima se entenderá que la PÓLIZA queda extinguida. En cualquier caso, el ASEGURADOR, cuando la PÓLIZA esté en suspenso, sólo podrá exigir el pago de la prima del período en curso. Si la PÓLIZA no hubiere sido resuelta o extinguida conforme a lo anterior, la cobertura vuelve a tener efecto a las veinticuatro (24) horas del día en que el TOMADOR pagó su prima.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-5 ">10.4.</span>
                <p className="flex-1 text-justify">
                El TOMADOR será notificado cuando exista la situación de un pago fallido para que pueda tomar las medidas que estime adecuadas en los plazos que resulten de aplicación.
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div id="articulo11" className="mt-10 px-4">
          <p className="text-left my-4 text-lg">
            <b>11. {" "}  &nbsp;&nbsp;&nbsp; Otras facultades, obligaciones y deberes  </b>
          </p>
          <div className="my-2 ml-3">
            <ul className="my-2 space-y-2 ml-5">
              <li className="flex flex-row ">
                <p className="flex-1 text-justify">
                El TOMADOR, y en su caso, el BENEFICIARIO tienen las siguientes obligaciones:
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-5 ">11.1.</span>
                <p className="flex-1 text-justify">
                Declarar, de acuerdo con el CUESTIONARIO DE SALUD, todas las circunstancias por él conocidas que puedan influir en la valoración del riesgo, quedando exonerado de tal deber si no se le somete a cuestionario o cuando, aun sometiéndoselo, se trate de circunstancias que puedan influir en la valoración del riesgo y que no estén comprendidas en él.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-5 ">11.2.</span>
                <p className="flex-1 text-justify">
                Comunicar, durante el curso del contrato y tan pronto como le sea posible, todas aquellas circunstancias anteriores y desconocidas a la fecha de CONTRATACIÓN que, de acuerdo con el CUESTIONARIO DE SALUD presentado antes de la perfección del contrato, agraven el riesgo y sean de tal naturaleza que, si hubieran sido conocidas por el ASEGURADOR en el momento de la perfección del contrato, no lo habría celebrado o lo habría concluido en condiciones más gravosas. En estos casos el ASEGURADOR podrá modificar o rescindir el contrato.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-5 ">11.3.</span>
                <p className="flex-1 text-justify">
                Comunicar, tan pronto como le sea posible, el cambio de domicilio. Si el cambio supone una agravación del riesgo, será de aplicación lo previsto en el punto 11.1.2 anterior. Si por el contrario supone una disminución del riesgo, será de aplicación lo dispuesto en la legislación vigente, reduciéndose el importe de la prima en la proporción correspondiente a partir del vencimiento de la PÓLIZA.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-5 ">11.4.</span>
                <p className="flex-1 text-justify">
                Comunicar al ASEGURADOR, tan pronto como le sea posible, las bajas de BENEFICIARIOS que se produzcan durante la vigencia del presente contrato, que tendrán efecto según lo previsto en el Artículo 9 anterior.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-5 ">11.5.</span>
                <p className="flex-1 text-justify">
                Comunicar el siniestro (EMERGENCIA MÉDICA) a ASISTENSI siempre previamente a la asistencia médica, aportando la documentación requerida. En caso de incumplimiento de esta obligación, el ASEGURADOR podrá rechazar la cobertura del siniestro, salvo en los casos de ingresos por EMERGENCIA MÉDICA en los que se estará a lo previsto en el apartado correspondiente.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-5 ">11.6.</span>
                <p className="flex-1 text-justify">
                Aminorar las consecuencias del siniestro, empleando los medios a su alcance para el pronto restablecimiento. El incumplimiento de este deber, o la manifiesta intención de perjudicar o engañar al ASEGURADOR, liberarán a ésta de toda prestación derivada del siniestro.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-5 ">11.7.</span>
                <p className="flex-1 text-justify">
                El ASEGURADOR puede subrogarse en los derechos relativos a los gastos de asistencia sanitaria cuando se produzcan como consecuencia de lesiones cubiertas por cualquier otro seguro, y en concreto, las asistencias causadas por accidentes laborales, enfermedades profesionales y las amparadas por el seguro obligatorio de vehículos a motor. El ASEGURADOR, si hubiese cubierto la asistencia podrá ejercitar las acciones que procedan para recuperar el coste que tales asistencias le hubieran supuesto. El BENEFICIARIO o, en su caso, el TOMADOR, queda obligado a facilitar al ASEGURADOR los datos y la colaboración necesaria para ello.
                </p>
              </li>
              <li className="flex flex-row ">
                <span className="pr-5 ">11.8.</span>
                <p className="flex-1 text-justify">
                ZONAS DE DIFÍCIL ACCESO DENTRO DEL ÁMBITO DE COBERTURA: Si el BENEFICIARIO que requiera el Servicio se encontrara en áreas geográficas de notoria peligrosidad, difícil acceso o en horarios que impliquen situación de riesgo a discreción de ASISTENSI, el BENEFICIARIO estará obligado a realizar sus mejores esfuerzos para cooperar con el personal de ASISTENSI en la tarea de coordinar las actividades que sean necesarias, a los fines de tratar de garantizar la prestación del servicio, y dependerá de las circunstancias el que se pueda o no acceder al sitio, para atender y trasladar al BENEFICIARIO. Si por situaciones inesperadas y adversas en el proceso de visita y/o búsqueda no se pudiera acceder hasta el sitio indicado, ASISTENSI deberá contactar telefónicamente al BENEFICIARIO para establecer otras acciones a seguir.
                </p>
              </li>
            </ul>
            <div className="px-4 text-justify bg-gray-400 border-2 border-gray-600 ml-16 mt-5">
              <p className="text-justify my-4">
              El BENEFICIARIO y el TOMADOR renuncian a emprender acciones legales frente a ACUNSA y/o ASISTENSI por mala praxis médica u hospitalaria o falta de calidad del servicio médico o paramédico o de la asistencia sanitaria prestada, obligándose en todo caso a plantear acciones exclusivamente contra los profesionales o centros intervinientes directamente en la prestación y sus respectivas aseguradoras de responsabilidad civil.
              </p>
            </div>
          </div>
        </div>

        <div id="articulo12" className="mt-10 px-4">
          <p className="text-left my-4 text-lg">
            <b>12. {" "}  &nbsp;&nbsp;&nbsp; Actualización anual de las Condiciones Económicas de la Póliza</b>
          </p>
          <div className="my-2 ml-3">
            <p className="text-justify my-4 ">
            El ASEGURADOR actualizará anualmente el importe de la prima. Estas actualizaciones incorporarán los ajustes necesarios para garantizar que las tarifas de prima sean suficientes y se basarán en la edad de los BENEFICIARIOS, en el incremento de los costes de los medicamentos y servicios sanitarios, en el incremento de la incidencia de las enfermedades y patologías cubiertas por el seguro, en la incorporación de nuevas tecnologías y en otros hechos de características similares. Además de lo anterior, el ASEGURADOR podrá calcular la prima de la póliza en función de otros factores de riesgo como puede ser la zona geográfica. </p>
          </div>
        </div>

        <div id="articulo13" className="mt-10 px-4">
          <p className="text-left my-4 text-lg">
            <b>13. {" "}  &nbsp;&nbsp;&nbsp; Tratamiento de Datos de Carácter Personal</b>
          </p>
          <div className="my-2 ml-3">
            <p className="text-justify my-4 ">
            ACUNSA con domicilio social en Avenida Pío XII, 57 (31008) Pamplona, es el responsable del tratamiento de sus datos personales y le informa que estos datos serán tratados de con- formidad con lo dispuesto en la normativa vigente de protección de datos personales, el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (LOPD-GDD).
            </p>
            <p className="text-justify my-4 ">
            ACUNSA recoge y utiliza la información personal (del TOMADOR, del ASEGURADO o del BENEFICIARIO) que se le proporcione y cualquier otro dato personal derivado de la relación jurídica que el mismo regula, incluidos los datos de salud, con los siguientes fines y bajo su expreso consentimiento y amparada también la legitimación de su trata- miento en la ejecución del contrato y el cumplimiento de obligaciones legales, según lo establecido en la normativa vigente de Protección de Datos de Carácter Personal:
              <ul className="list-disc my-2 space-y-2 ml-10">
                <li>Formalizar, desarrollar y ejecutar la actividad aseguradora y/o cumplimiento del contrato, así como de las obligaciones legales. (Consentimiento obligatorio, necesario para la finalidad contractual).</li>

                <li>Valoración y delimitación del riesgo, presupuesto, solicitud, contrato del seguro, cobro de la prima, pago de las prestaciones, evaluación médica o tramitación de reembolsos. (Consentimiento obligatorio, necesario para la finalidad contractual)</li>

                <li>Cesión total o parcial de los datos personales del Tomador y/o Asegurados: al propio TOMADOR (persona jurídica en relación a los datos de sus asegurados particulares); a otras entidades aseguradoras (reaseguradoras o coaseguradoras) o colaboradoras con el objeto de gestionar y administrar la póliza, y por tanto de los siniestros que estén dentro de la cobertura del contrato del seguro suscrito. (Consentimiento obligatorio, necesario para la finalidad contractual)</li>

                <li>Cesión total o parcial de esta información a otras instituciones colaboradoras con el sector ASEGURADOR con fines estadísticos y de lucha contra el fraude. (Consentimiento obligatorio, necesario para la finalidad contractual)</li>

                <li>Cesión total o parcial de esta información a Organismos Públicos y Registros Públicos del sector ASEGURADOR, a centros médicos, al Consorcio de Compensación de Seguros. (Consentimiento obligatorio, necesario para la finalidad contractual)</li>

                <li>Cesión total o parcial de esta información a terceros implicados que pueden ser facultativos médicos, peritos y abogados de terceros para cumplir cualquier obligación legal, incluyendo aquellas con la oficina de Defensa del Consumidor, en caso de que el ASEGURADO presente una reclamación. (Consentimiento obligatorio, necesario para la finalidad contractual)</li>
              </ul>
            </p>
            <p className="text-justify my-4 ">
            Dichos datos serán almacenados y tratados por dichos cesionarios únicamente en las condiciones descritas y para las finalidades detalladas.
            </p>
            <p className="text-justify my-4 ">
            Para el caso de que como TOMADOR de la póliza nos proporcione información sobre otras personas, deberá asegurarse de haber obtenido previamente su consentimiento para el tratamiento y comunicación de sus datos personales a ACUNSA, haciéndose responsable de que conozcan las condiciones de privacidad referidas, así mismo, el TOMADOR, en caso de que se produzca alguna variación en los datos facilitados a ACUNSA para su tratamiento, conforme a lo indicado en la presente cláusula, deberá notificarlo al ASEGURADOR.
            </p>
            <p className="text-justify my-4 ">
            Los datos recabados son imprescindibles para el establecimiento y desarrollo de la relación contractual. El TOMADOR garantiza que los datos personales facilitados son veraces y se hace responsable de comunicar cualquier modificación de los mismos para que, en todo momento respondan a su situación actual.
            </p>
            <p className="text-justify my-4 ">
            ACUNSA asume la adopción de las medidas de seguridad de índole técnica y organizativa para proteger la confidencialidad e integridad de la información, de acuerdo con lo establecido en el Reglamento 2016/679 de Protección de Datos y demás legislación aplicable y ante quien el titular de los datos puede ejercitar, en cualquier momento, sus derechos de acceso, portabilidad, rectificación, oposición, limitación y supresión mediante comunicación escrita dirigida a ACUNSA a la dirección postal o en la dirección de correo electrónico: dpd@acunsa.es. En cualquier caso el solicitante o ASEGURADO deberá acreditar su identidad.
            </p>
            <p className="text-justify my-4 ">
            Puede encontrar información más detallada y actualizada sobre la Protección de Datos en el siguiente enlace: <a href={`https://www.acunsa.es/rgpd/`} className="cursor-pointer text-purple-500 font-bold underline" target="_blank" > www.acunsa.es/rgpd/ </a>
            </p>
            <p className="text-justify my-4 ">
            Asimismo, los interesados pueden presentar una reclamación ante la Autoridad de Control en materia de Protección de Datos competente, la Agencia Española de Protección de Datos en el siguiente enlace: <a href={`https://www.aepd.es`} className="cursor-pointer text-purple-500 font-bold underline" target="_blank" > www.aepd.es </a>
            </p>
          </div>
        </div>

        <div id="articulo14" className="mt-10 px-4">
          <p className="text-left my-4 text-lg">
            <b>14. {" "}  &nbsp;&nbsp;&nbsp; Comunicaciones</b>
          </p>
          <div className="my-2 ml-3">
            <p className="text-justify my-4 ">
            Las comunicaciones en relación con solicitud de los servicios de asistencia sanitaria previstos en esta PÓLIZA deberán realizarse a ASISTENSI conforme al procedimiento y por los medios previstos en el <a href={`#articulo7`} className="cursor-pointer text-purple-500 font-bold underline"> Artículo 7 </a>. anterior.
            </p>
            <p className="text-justify my-4 ">
            El resto de las comunicaciones serán dirigidas al Agente Exclusivo cuyos datos constan en las Condiciones Particulares al siguiente domicilio:
              <ul className="list-none my-2 space-y-2 ml-7">
                <li>Maytower, S.L.</li>
                <li>Calle Almagro, 3 </li>
                <li>Madrid 28010</li>
                <li>europa@asistensi.com</li>
              </ul>
            </p>
          </div>
        </div>

        <div id="articulo15" className="mt-10 px-4">
          <p className="text-left my-4 text-lg">
            <b>15. {" "}  &nbsp;&nbsp;&nbsp; Instancias de Reclamación</b>
          </p>
          <div className="my-2 ml-3">
            <p className="text-justify my-4 ">
            El presente contrato queda sometido a la jurisdicción española y, dentro de ella, será juez competente para el conocimiento de las acciones derivadas del mismo el del domicilio del TOMADOR.
            </p>
            <p className="text-justify my-4 ">
            Asimismo, las partes podrán someter voluntariamente sus divergencias a decisión arbitral en los términos de los artículos 57 y 58 del texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias, aprobados por el Real Decreto Legislativo 1/2007, de 16 de noviembre. En cualquier caso, y salvo aquellos supuestos en que la legislación de protección de los consumidores y usuarios lo impida, también podrán someter a arbitraje las cuestiones litigiosas, en los términos de la Ley 60/2003, de 23 de diciembre, de Arbitraje.
            </p>
            <p className="text-justify my-4 ">
            Igualmente, podrá someter sus divergencias a un mediador den los términos previstos en la Ley 5/2012, de 6 de julio, de mediación en asuntos civiles y mercantiles.
            </p>
            <p className="text-justify my-4 ">
            En caso de cualquier tipo de reclamación sobre la PÓLIZA, el TOMADOR, BENEFICIARIO o tercero perjudicado deberá dirigirse para su resolución a:
            </p>
            <p className="text-justify my-4 ">
            El Servicio de Atención al Asegurado de ACUNSA. Las reclamaciones podrán realizarse en soporte papel o por medios informáticos al correo electrónico: info@acunsa.es, especificando que se dirige a la atención del Departamento de Atención al Asegurado, siempre acreditando la identidad del solicitante.
            </p>
            <p className="text-justify my-4 ">
            Si la reclamación resulta denegada por el Servicio de Atención al Asegurado podrá acudir a una segunda instancia de reclamación externa e independiente de ACUNSA, el Defensor del Asegurado al correo electrónico: defensordelasegurado@legse.com, siempre acreditando la identidad del solicitante.
            </p>
            <p className="text-justify my-4 ">
            Si la reclamación resultara rechazada, denegada, o se cumpliera el plazo de un mes desde su presentación sin resolución por parte del Servicio de Atención al Asegurado, podrá iniciar el procedimiento administrativo de reclamación ante el Comisionado para la Defensa del Asegurado y del Partícipe de Planes de Pensiones de la Dirección General de Seguros y Fondos de Pensiones.
            </p>
          </div>
        </div>

        <div id="articulo16" className="mt-10 px-4">
          <p className="text-left my-4 text-lg">
            <b>16. {" "}  &nbsp;&nbsp;&nbsp; Prescripción</b>
          </p>
          <div className="my-2 ml-3">
            <p className="text-justify my-4 ">
            Las acciones derivadas de la presente PÓLIZA prescribirán en el término de cinco (5) años.
            </p>
          </div>
        </div>

        <div id="articulo17" className="mt-10 px-4">
          <p className="text-left my-4 text-lg">
            <b>17. {" "}  &nbsp;&nbsp;&nbsp; Información al Tomador del seguro y a los Asegurados</b>
          </p>
          <div className="my-2 ml-3">
            <p className="font-bold mt-5">¿Cuál es la naturaleza de este Contrato y la legislación aplicable?</p>
            <p className="text-justify my-2">
            Este es un contrato de seguro de salud que se rige por la Ley 50/1980 del Contrato de Seguro salvo aquellas que se indiquen expresamente en la propia PÓLIZA.
            </p>
            <p className="text-justify my-2">
            Si el contenido de la PÓLIZA difiere de la proposición de seguro o de las cláusulas acordadas, el Tomador del seguro podrá reclamar a ACUNSA en el plazo de un mes a contar desde la entrega de la Póliza para que subsane la divergencia existente. Transcurrido dicho plazo sin efectuar la reclamación se estará a lo dispuesto en la PÓLIZA.
            </p>
            <p className="font-bold mt-5">¿Cuál es la denominación del ASEGURADOR, su forma jurídica y su dirección?</p>
            <p className="text-justify my-2">
            El ASEGURADOR de esta póliza es Asistencia Clínica Universidad de Navarra, S.A. de Seguros y Reaseguros, domiciliada en, Pamplona (Navarra), en AV PIO XII, 57.
            </p>
            <p className="text-justify my-2">
            ACUNSA está inscrita en la Dirección General de Seguros y Fondos de Pensiones con el número C0325.
            </p>
            <p className="font-bold mt-5">¿Cuál es el Órgano de Control del ASEGURADOR?</p>
            <p className="text-justify my-2">
            ACUNSA está autorizada y regulada por la Dirección General de Seguros y Fondos de Pensiones.
            </p>
            <p className="text-justify my-2">
            Paseo de la Castellana 44, 28046, Madrid
            </p>
            <p className="text-justify my-2">
            <a href={`http://www.dgsfp.mineco.es/es/Paginas/Iniciocarrousel.aspx`} className="cursor-pointer text-purple-500 font-bold underline" target="_blank"> http://www.dgsfp.mineco.es/es/Paginas/Iniciocarrousel.aspx</a>

            </p>
            <p className="text-justify my-2">
              Usted podrá acceder al informe sobre la situación financiera y de solvencia ACUNSA, regulado en el artículo 80 de la Ley 20/2015, de 14 de julio en el siguiente enlace: <a href={`https://www.acunsa.es/wp-content/uploads/2021/04/situacion-financiera-y-solvencia-2020.pdf`} className="cursor-pointer text-purple-500 font-bold underline" target="_blank"> https://www.acunsa.es/wp-content/uploads/2021/04/situacion-financiera-y-solvencia-2020.pdf</a>
            </p>
          </div>
        </div>

        <div id="articulo18" className="mt-10 px-4">
          <p className="text-left my-4 text-lg">
            <b>18. {" "}  &nbsp;&nbsp;&nbsp; Sanciones internacionales. Entrada en vigor.</b>
          </p>
          <div className="my-2 ml-3">
            <p className="text-justify my-4 underline ">
            El ASEGURADOR no proporcionará ninguna cobertura ni realizará pago alguno bajo esta PÓLIZA si para ello tuviera que incurrir en una violación de cualesquiera leyes o normas reguladoras de sanciones económicas internacionales que pudieran exponer al ASEGURADOR o a cualquiera de las compañías de su grupo a la imposición de multas o sanciones
            </p>
            <p className="text-justify my-4 underline ">
            Igualmente, si la suscripción de esta PÓLIZA con el TOMADOR y/o la inclusión de cualquier BENEFICIARIO bajo la misma pudiera dar lugar a una violación de cualesquiera leyes o normas reguladoras de sanciones económicas internacionales que pudieran exponer al ASEGURADOR o a cualquiera de las compañías de su grupo a la imposición de multas o sanciones, esta PÓLIZA no entrará en vigor (en caso de que el hecho que pudiera dar lugar a dichas sanciones sea la suscripción con el TOMADOR) o no entrará en vigor respecto del BENEFICIARIO cuya inclusión en la PÓLIZA pudiera dar lugar a dichas sanciones.
            </p>
            <p className="text-justify my-4 font-bold ">
            Mediante mi firma declaro haber recibido, leído y entendido todos los términos y condiciones de la presente póliza, con especial referencia a aquellas cláusulas que, debidamente resaltadas, pudieran ser limitativas de los derechos del Asegurado/Beneficiario.
            </p>
            <div className="flex m-auto mt-20 justify-between font-bold w-10/12">
              <p>Firma del Tomador</p>
              <p>Firma del Asegurador</p>
            </div>
          </div>
        </div>


        <div className="mt-32 px-4">
          <p className="text-left my-4 text-lg">
            <b>ANEXO A – COBERTURAS Y EXCLUSIONES</b>
          </p>
          <div className="my-2 ml-3">
            <p className="text-justify my-4">
            Según lo establecido en el Artículo 3 "Objeto del Seguro", el desglose de las coberturas de asistencia sanitaria de la PÓLIZA es el que figura en este Anexo.
            </p>
            <p className="text-justify my-4">
              Las coberturas de asistencia sanitaria establecidas en la presente póliza son las siguientes (las “<b>Coberturas</b>”):
              <ul className="list-decimal my-3 space-y-2 ml-10">
                <li>ASISTENCIA MÉDICA IN SITU</li>
                <li>PRUEBAS DE LABORATORIO CLÍNICO</li>
                <li>ENTREGA DE MEDICAMENTOS</li>
                <li>TRASLADOS</li>
                <li>COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD <b>(únicamente caso de que haya sido expresamente contratada y así conste en las Condiciones Particulares)</b>,</li>
              </ul>
            </p>
            <p className="text-justify my-4">
            El alcance de las Coberturas será el que se detalla a continuación, no siendo válidas definiciones o interpretaciones que pudieran hacerse en documentos distintos a estas Condiciones Generales. Asimismo, se concretan dentro de las condiciones algunas cláusulas limitativas y delimitadoras de las Coberturas.
            </p>
            <p className="text-justify my-4">
            Para el acceso a las Coberturas de la póliza <b>existe un periodo de espera o de carencia detallado</b> en el <a href={`#articulo5`} className="cursor-pointer text-purple-500 font-bold underline"> Artículo 5</a>.
            </p>
            <p className="text-justify my-4">
            Tenga en cuenta que las coberturas de esta PÓLIZA están sujetas a las <b>exclusiones</b> indicadas en Sección II de este Anexo y a los <b>límites</b> indicados en las Condiciones Particulares.
            </p>
          </div>
        </div>


        <div  className="mt-20 px-4">
          <p className="text-center my-4 text-lg">
            <b>SECCIÓN I: CATÁLOGO DE COBERTURAS</b>
          </p>
          <p id="cobertura1" className="text-left my-4 text-lg">
            <b>COBERTURA 1. {" "}  &nbsp;&nbsp;&nbsp; ASISTENCIA MÉDICA IN SITU.</b>
          </p>
          <div className="my-2 ml-3">
            <p className="text-justify my-4">
            Es la prestación del servicio de atención médica, que los médicos del SISTEMA COORDINADO DE PROVEEDORES ofrecerán en el lugar donde se encuentre el BENEFICIARIO, mediante las siguientes modalidades de atención:
            </p>
            <ul className="my-2 space-y-4 ml-0">
              <li>
                <div className="flex flex-row font-bold ">
                  <span className="pr-5 ">1.1.</span>
                  <p className="flex-1 text-justify">
                  ORIENTACIÓN MÉDICA TELEFÓNICA 
                  </p>
                </div>
                <p className="text-justify my-2 ml-12">
                Procura de atención y orientación médica telefónica como primer contacto con ASISTENSI y ASISTENSI realizará la coordinación del servicio, en los cuales no se requiere de una Unidad Médica. Puede ser un medio alternativo para la prestación del beneficio de Orientación Médico Virtual si el BENEFICIARIO contactó a ASISTENSI por teléfono. Se pueden enviar receta médica e indicaciones por esta vía.
                </p>
              </li>
              <li>
                <div className="flex flex-row font-bold ">
                  <span className="pr-5 ">1.2.</span>
                  <p className="flex-1 text-justify">
                  ORIENTACIÓN MÉDICA VIRTUAL
                  </p>
                </div>
                <p className="text-justify my-2 ml-12">
                El equipo de salud de ASISTENSI a través de videoconferencia, realizará una clasificación que va a determinar una toma de decisiones en cuanto a la asistencia del BENEFICIARIO, basándose en función de los síntomas y manifestaciones subjetivas del BENEFICIARIO o acompañante, en aras de priorizar la atención médica.
                </p>
              </li>
              <li>
                <div className="flex flex-row font-bold ">
                  <span className="pr-5 ">1.3.</span>
                  <p className="flex-1 text-justify">
                  ATENCIÓN MÉDICA DOMICILIARIA
                  </p>
                </div>
                <p className="text-justify my-2 ml-12">
                El equipo de salud de ASISTENSI realizará una clasificación que va a determinar una toma de decisiones en cuanto a la asistencia del BENEFICIARIO, basándose en función de los síntomas y manifestaciones subjetivas del BENEFICIARIO o acompañante, en aras de priorizar la atención médica, enviando un médico y paramédico al sitio en donde se encuentra el BENEFICIARIO para la realización de una consulta médica que puede derivar en el diagnóstico y tratamiento en el sitio, y solicitando un traslado en ambulancia para la derivación del caso a un nivel superior de prestación de servicios de salud.
                </p>
                <p className="text-justify my-2 ml-12">
                Es importante tomar en cuenta que <span className="underline font-bold">a ORIENTACIÓN MÉDICA DOMICILIARIA es un servicio de atención primaria</span> y en el marco de la misma <span className="underline font-bold">hay procedimientos que están excluidos</span>  tales como: <b>sutura, nebulizaciones, hidratación, cambios de sondas vesicales o curas periódicas de heridas, entre otros.</b>
                </p>
              </li>
              <li>
                <div className="flex flex-row font-bold ">
                  <span className="pr-5 ">1.4.</span>
                  <p className="flex-1 text-justify">
                    Sistema de clasificación, prioridad y autorizaciones:
                    <ul className="my-2 space-y-2 ml-3">
                      <li className="flex flex-row ">
                        <span className="pr-5 ">a)</span>
                        <p className="flex-1 text-justify">
                        ORIENTACIÓN MÉDICA TELEFÓNICA y ORIENTACIÓN MÉDICA VIRTUAL (Código Blanco): se trata de la orientación médica, dando un soporte básico al BENEFICIARIO ante determinadas patologías.
                        </p>
                      </li>
                      <li className="flex flex-row ">
                        <span className="pr-5 ">b)</span>
                        <p className="flex-1 text-justify">
                        Atención médica no urgente (Código Verde): se trata de una asistencia primaria para atender patologías comunes y/o enfermedades crónicas que no revistan peligro inminente para el BENEFICIARIO.
                        </p>
                      </li>
                      <li className="flex flex-row ">
                        <span className="pr-5 ">c)</span>
                        <p className="flex-1 text-justify">
                        EMERGENCIA MÉDICA (Código Amarillo): si el médico lo considera y lo solicita, tendrá el respaldo de una UNIDAD MÉDICA, o del medio de transporte que considere mejor para el traslado del BENEFICIARIO a un CENTRO DE SALUD que será especificado por él mismo o su acompañante, que, en caso de que la EMERGENCIA MÉDICA esté cubierta por la COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD, será uno de los que integren el SISTEMA COORDINADO DE PROVEEDORES.
                        </p>
                      </li>
                      <li className="flex flex-row ">
                        <span className="pr-5 ">d)</span>
                        <p className="flex-1 text-justify">
                        EMERGENCIA MÉDICA (Código Rojo): cuando se reciba a través de ORIENTACIÓN MÉDICA TELEFÓNICA O VIRTUAL un caso de EMERGENCIA MÉDICA se activará el procedimiento para su atención a través del personal médico y técnico, dándose prioridad a la solicitud. Se enviará el personal médico simultáneamente con una UNIDAD MÉDICA hasta el lugar de ocurrencia. Luego de la estabilización del BENEFICIARIO, éste será trasladado al CENTRO DE SALUD, pudiendo este ser especificado por el BENEFICIARIO o su acompañante teniendo de entre los que formen parte en cada momento del SISTEMA COORDINADO DE PROVEEDORES, garantizando el ingreso del BENEFICIARIO para aquellos casos que la EMERGENCIA MÉDICA esté cubierta bajo la COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD.
                        </p>
                      </li>
                    </ul>
                  </p>
                </div>
                <p className="text-justify my-2 ml-12">
                La Cobertura de Asistencia Médica in Situ <b>está limitada a un uso razonable, no indiscriminado ni excesivo</b> y sólo en caso de necesidad.
                </p>
                <p className="text-justify my-2 ml-12">
                La Cobertura de Asistencia Médica in Situ está <b>sujeta al HORARIO DE SERVICIO </b>(ver Artículo 2. Definiciones).
                </p>
              </li>
            </ul>
          </div>


          <p id="cobertura2" className="text-left my-4 text-lg mt-10">
            <b>COBERTURA 2. {" "}  &nbsp;&nbsp;&nbsp; PRUEBAS DE LABORATORIO CLÍNICO</b>
          </p>
          <div className="my-2 ml-3">
            <p className="text-justify my-4">
            Son un tipo de exploración confirmatoria solicitada por un médico al laboratorio clínico para confirmar o descartar un diagnóstico. Forma parte del proceso de atención médica al BENEFICIARIO y se apoya en el estudio de distintas muestras biológicas mediante su análisis en laboratorio brindando un resultado objetivo que puede ser cuantitativo o cualitativo. Dichas pruebas se realizarán con el proveedor que elija el BENEFICIARIO, procediendo ASISTENSI al reembolso del importe incurrido por el BENEFICIARIO o TOMADOR a tal fin.
            </p>
            <ul className="my-2 space-y-4 ml-0">
              <li>
                <div className="flex flex-row ">
                  <span className="pr-5 font-bold">2.1.</span>
                  <p className="flex-1 text-justify">
                    <b>LÍMITES: </b> el BENEFICIARIO <span className="underline font-bold">tendrá derecho a dos (2) pruebas de laboratorio por año</span>, independientemente de la modalidad del PLAN, <b>siempre y cuando se tenga receta o indicación médica previa</b> y deberán pedirse directamente a ASISTENSI a través de la App o de la CENTRAL DE ALARMA TELEFÓNICA.
                  </p>
                </div>
              </li>
              <li>
                <div className="flex flex-row ">
                  <span className="pr-5 font-bold">2.2.</span>
                  <p className="flex-1 text-justify">
                  <b>AUTORIZACIÓN PREVIA OBLIGATORIA: </b> únicamente las pruebas <b>previamente autorizadas por ASISTENSI</b> serán cubiertas por el PLAN. <b>No se cubrirán pruebas realizadas con anterioridad a la autorización de ASISTENSI</b>.
                </p>
                </div>
              </li>
              <li>
                <div className="flex flex-row ">
                  <span className="pr-5 font-bold">2.3.</span>
                  <p className="flex-1 text-justify">
                    <b>PRUEBAS INCLUIDAS: </b> las PRUEBAS DE LABORATORIO CLÍNICO a las que tiene acceso el BENEFICIARIO, y los estudios que incluye cada uno, son <b>única y exclusivamente</b> perfil infección urinaria, perfil infección básico, perfil infección completo y perfil infección enteral:
                    <div className="my-5 flex-wrap w-full space-y-10">
                      <table className="border border-gray-700 w-full text-sm">
                        <thead className="border border-gray-700 ">
                          <tr>
                            <th colSpan="2" className="text-center text-pink-500">PERFIL INFECCIÓN URINARIA</th>
                          </tr>
                          <tr className="bg-purple-450 border border-gray-700">
                            <th className=" text-white text-center">Nombre <br/>Procedimiento</th>
                            <th className=" text-white text-center">Descripción<br/> Común</th>
                          </tr>
                        </thead>
                        <tbody className="text-xs">
                          <tr className="border border-gray-600 text-xs"> <td>HEMOGRAMA</td> <td>HEMOGRAMA</td> </tr>
                          <tr className="border border-gray-600 text-xs"> <td>URIANALISIS</td> <td>URIANALISIS</td> </tr>
                          <tr className="border border-gray-600 text-xs"> <td>CREATININA</td> <td>CREATININA</td> </tr>
                          <tr className="border border-gray-600 text-xs"> <td>BUN</td> <td>BUN</td> </tr>
                          <tr className="border border-gray-600 text-xs"> <td>CULTIVO DE ORINA</td> <td>CULTIVO DE ORINA</td> </tr>
                          <tr className="border border-gray-600 text-xs"> <td>ERITROSEDIMENTACION</td> <td>ERITROSEDIMENTACION</td> </tr>
                        </tbody>
                      </table>

                      <table className="border border-gray-700 w-full text-sm">
                        <thead className="border border-gray-700 ">
                          <tr>
                            <th colSpan="2" className="text-center text-pink-500">PERFIL INFECCIÓN BÁSICO</th>
                          </tr>
                          <tr className="bg-purple-450 border border-gray-700">
                            <th className=" text-white text-center">Nombre <br/>Procedimiento</th>
                            <th className=" text-white text-center">Descripción<br/> Común</th>
                          </tr>
                        </thead>
                        <tbody className="text-xs">
                          <tr className="border border-gray-600 text-xs"> <td>HEMOGRAMA</td> <td>HEMOGRAMA</td> </tr>
                          <tr className="border border-gray-600 text-xs"> <td>GLUCOSA</td> <td>GLUCOSA/GLICEMIA</td> </tr>
                          <tr className="border border-gray-600 text-xs"> <td>CREATININA</td> <td>CREATININA</td> </tr>
                          <tr className="border border-gray-600 text-xs"> <td>BUN</td> <td>BUN</td> </tr>
                          <tr className="border border-gray-600 text-xs"> <td>ERITROSEDIMENTACION</td> <td>ERITROSEDIMENTACION</td> </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="my-5 flex-wrap w-full space-y-10">
                      <table className="border border-gray-700 w-full text-sm">
                        <thead className="border border-gray-700 ">
                          <tr>
                            <th colSpan="2" className="text-center text-pink-500">PERFIL INFECCIÓN ENTERAL</th>
                          </tr>
                          <tr className="bg-purple-450 border border-gray-700">
                            <th className=" text-white text-center">Nombre <br/>Procedimiento</th>
                            <th className=" text-white text-center">Descripción<br/> Común</th>
                          </tr>
                        </thead>
                        <tbody className="text-xs">
                          <tr className="border border-gray-600 text-xs"> <td>HEMOGRAMA</td> <td>HEMOGRAMA</td> </tr>
                          <tr className="border border-gray-600 text-xs"> <td>CREATININA</td> <td>CREATININA</td> </tr>
                          <tr className="border border-gray-600 text-xs"> <td>BUN</td> <td>BUN</td> </tr>
                          <tr className="border border-gray-600 text-xs"> <td>COPROLOGICO</td> <td>COPROLOGICO</td> </tr>
                          <tr className="border border-gray-600 text-xs"> <td>ERITROSEDIMENTACION</td> <td>ERITROSEDIMENTACION</td> </tr>
                        </tbody>
                      </table>

                      <table className="border border-gray-700 w-full text-sm">
                        <thead className="border border-gray-700 ">
                          <tr>
                            <th colSpan="2" className="text-center text-pink-500">PERFIL INFECCIÓN COMPLETO </th>
                          </tr>
                          <tr className="bg-purple-450 border border-gray-700">
                            <th className=" text-white text-center">Nombre <br/>Procedimiento</th>
                            <th className=" text-white text-center">Descripción<br/> Común</th>
                          </tr>
                        </thead>
                        <tbody className="text-xs">
                          <tr className="border border-gray-600 text-xs"> <td>HEMOGRAMA</td> <td>HEMOGRAMA</td> </tr>
                          <tr className="border border-gray-600 text-xs"> <td>BUN</td> <td>BUN</td> </tr>
                          <tr className="border border-gray-600 text-xs"> <td>SGPT</td> <td>SGPT</td> </tr>
                          <tr className="border border-gray-600 text-xs"> <td>SGOT</td> <td>SGOT</td> </tr>
                          <tr className="border border-gray-600 text-xs"> <td>PCR</td> <td>PCR</td> </tr>
                          <tr className="border border-gray-600 text-xs"> <td>GLUCOSA</td> <td>GLUCOSA/GLICEMIA</td> </tr>
                          <tr className="border border-gray-600 text-xs"> <td>CREATININA</td> <td>CREATININA</td> </tr>
                        </tbody>
                      </table>
                    </div>
                  </p>
                </div>
              </li>
            </ul>
          </div>


          <p id="cobertura3" className="text-left my-4 text-lg mt-10">
            <b>COBERTURA 3. {" "}  &nbsp;&nbsp;&nbsp; ENTREGA DE MEDICAMENTOS</b>
          </p>
          <div className="my-2 ml-3">
            <p className="text-justify my-4">
              Forma parte del proceso de atención médica al BENEFICIARIO y consiste en la entrega del INVENTARIO DOMICILIARIO y del INVENTARIO CRÍTICO.
            </p>
            <ul className="my-2 space-y-4 ml-0">
              <li>
                <div className="flex flex-row ">
                  <span className="pr-5 font-bold">3.1.</span>
                  <p className="flex-1 text-justify">
                    <b>INVENTARIO DOMICILIARIO: </b> Entrega de medicamentos para la administración vía oral, inhalados, gotas y tópicos que sean indicados en la receta médica entregado por el médico domiciliario de ASISTENSI y/o un médico calificado de un CENTRO DE SALUD. El BENEFICIARIO podrá solicitar los medicamentos indicados por el médico en el ámbito de la ASISTENCIA MÉDICA IN SITU y/o consulta privada. El personal de ASISTENSI procederá a la entrega de los medicamentos en el domicilio del BENEFICIARIO en un período no mayor a seis (6) horas para Caracas y no mayor a doce (12) horas en el resto del ÁMBITO TERRITORIAL.
                    <ul className="my-2 space-y-4 ml-0 mt-5">
                      <li>
                        <div className="flex flex-row ">
                          <span className="pr-5">3.1.1.</span>
                          <p className="flex-1 text-justify">
                            <b>LÍMITES: </b> El límite de cantidades de insumo es suficiente para cubrir hasta un <b>máximo de siete (7) días continuos de tratamiento, por PATOLOGÍA/EVENTO</b>, cubriendo ASISTENSI el importe total del medicamento. Cualquier dosis adicional requerida deberá ser pagada por el BENEFICIARIO y no generará reembolso para el BENEFICIARIO.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="flex flex-row ">
                          <span className="pr-5">3.1.2.</span>
                          <p className="flex-1 text-justify">
                            <b>SOLICITUD DE COBERTURA: </b> el BENEFICIARIO deberá entregar informe o receta médica a su nombre a través del Correo Electrónico o la aplicación (App) móvil de ASISTENSI, debidamente suscritos por un médico colegiado, debidamente fechados y presentados a ASISTENSI dentro de las cuarenta y ocho (48) horas siguientes a su emisión. ASISTENSI podrá realizar el análisis de cada una de las solicitudes para asegurar que exista relación entre el diagnóstico y tratamiento indicado, así como para validar que las dosis indicadas del medicamento sean acordes al esquema regular de tratamiento indicado por patología y medicamento de acuerdo con los estándares internacionales. Una vez realizada la solicitud, se podrá realizar modificaciones a la misma, las cuales serán notificadas al usuario de forma pertinente.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="flex flex-row ">
                          <span className="pr-5">3.1.3.</span>
                          <p className="flex-1 text-justify">
                            <b>INVENTARIO INCLUIDO: </b> El listado de medicamentos incluidos para la entrega al BENEFICIARIO es el que se especifica en el <b>Apéndice 3.1.3 al Anexo A</b>.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </p>
                </div>
              </li>

              <li>
                <div className="flex flex-row ">
                  <span className="pr-5 font-bold">3.2.</span>
                  <p className="flex-1 text-justify">
                    <b>INVENTARIO CRÍTICO (hospitalario/clínico): </b> Entrega de medicamentos indispensables para asegurar la atención eficaz y oportuna de los BENEFICIARIOS ingresados en un CENTRO DE SALUD durante las primeras setenta y dos (72) horas, consideradas como periodo crítico de actuación con respecto a las patologías listadas en el <b>Apéndice 3.2 al Anexo A</b>.
                    <ul className="my-2 space-y-4 ml-0 mt-5">
                      <li>
                        <div className="flex flex-row ">
                          <span className="pr-5">3.2.1.</span>
                          <p className="flex-1 text-justify">
                            <b>LÍMITES: </b> El límite de cantidades de insumo es suficiente para cubrir <b>hasta un máximo de setenta y dos (72) horas de tratamiento en CENTROS DE SALUD, por PATOLOGÍA/EVENTO</b>, cubriendo ASISTENSI el importe total del medicamento. Cualquier dosis adicional requerida por fuera del límite de tiempo de cobertura deberá ser pagada por el BENEFICIARIO y no será reembolsable.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="flex flex-row ">
                          <span className="pr-5">3.2.2.</span>
                          <p className="flex-1 text-justify">
                            <b>SOLICITUD DE COBERTURA: </b> el BENEFICIARIO, hospitalizado en un CENTRO DE SALUD por EMERGENCIA MÉDICA, deberá entregar informe o receta médica a su nombre a través del Correo Electrónico o la aplicación (App) móvil de ASISTENSI. </p>
                        </div>
                      </li>
                      <li>
                        <div className="flex flex-row ">
                          <span className="pr-5">3.2.3.</span>
                          <p className="flex-1 text-justify">
                            <b>INVENTARIO INCLUIDO: </b> El listado de medicamentos incluidos para la entrega al BENEFICIARIO es el que se especifica en el <b>Apéndice 3.2.3 al Anexo A</b>.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </p>
                </div>
              </li>
            </ul>
          </div>


          <p id="cobertura4" className="text-left my-4 text-lg mt-10">
            <b>COBERTURA 4. {" "}  &nbsp;&nbsp;&nbsp; TRASLADOS</b>
          </p>
          <div className="my-2 ml-3">
            <p className="text-justify my-4">
            Coordinación y movilización de personal médico y técnico y de una UNIDAD MÉDICA, para la prestación del servicio en los siguientes casos: TRASLADO DE RETORNO o ALTA MÉDICA; TRASLADO DE TERAPIA INTENSIVA; TRASLADO A OTRO CENTRO DE SALUD o INTERINSTITUCIONAL; TRASLADO EN AMBULANCIAS; y TRASLADO PROGRAMADO.
            </p>
            <p className="text-justify my-4">
            Cualquier TRASLADO <b>requerirá recomendación médica y autorización previa</b> por ASISTENSI para su prestación.
            </p>
          </div>


          <p id="cobertura4" className="text-left my-4 text-lg mt-10">
            <b>COBERTURA 5. {" "}  &nbsp;&nbsp;&nbsp; COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD</b>
          </p>
          <div className="my-2 ml-3">
            <p className="text-justify my-4">
            Es la Cobertura ampara los gastos médicos ocasionados durante la vigencia de la Póliza por una EMERGENCIA MÉDICA en el territorio de la República Bolivariana de Venezuela que amerite la atención del BENEFICIARIO en un CENTRO DE SALUD en virtud de no haber sido solventada en su totalidad por los servicios médicos prestados previamente bajo las demás coberturas de la Póliza. Esta cobertura tiene un límite de CINCO MIL DÓLARES AMERICANOS, USD. 5.000 o DIEZ MIL DÓLARES AMERICANOS, USD 10.000 que serán aplicados por BENEFICIARIO, por enfermedad o accidente y vigencia anual, pagaderos en Bolívares al CENTRO DE SALUD, calculados en base al FACTOR DE TASA DE CAMBIO.
            </p>
            <p className="text-justify my-4">
            El BENEFICIARIO será atendido inicialmente por ASISTENSI (por vía remota o presencial), quien determinará que se trata de una emergencia médica que requiere ser tratada en un CENTRO DE SALUD y velará para que el BENEFICIARIO reciba médica atención inmediata. En caso de emergencias graves (accidentes, inconsciencia, dolor torácico sugestivo de infarto al miocardio, etc) el BENEFICIARIO podrá trasladarse por sus propios medios a la clínica, siendo recomendable llamar a la central de alarma telefónica para recibir un mejor servicio en la clínica.
            </p>
            <p className="text-justify my-4">
            PLAN ASISTENSI: USD$5,000.00 por caso o evento por año
            </p>
            <p className="text-justify my-4">
            PLAN ASISTENSI PLUS: USD$10,000.00 por caso o evento por añoCubre aquellos gastos médicos derivados de accidentes o enfermedades comunes producidos durante la vigencia de contrato por una EMERGENCIA MÉDICA en el ÁMBITO TERRITORIAL que requiera la atención del BENEFICIARIO en un CENTRO DE SALUD en virtud de no haber sido solventada en su totalidad la atención del BENEFICIARIO por los servicios médicos prestados previamente por ACUNSA a través de ASISTENSI.
            </p>
            <p className="text-justify my-4">
            Esta cobertura sólo es aplicable en caso de que haya sido específicamente contratada y así conste en las Condiciones Particulares.
            </p>
            <p className="text-justify my-4">
            La cobertura a la que tendrán derecho los Beneficiarios es la que se describe a continuación:
            </p>
            <ul className="my-2 space-y-4 ml-0">
              <li>
                <div className="flex flex-row ">
                  <span className="pr-5 ">I.</span>
                  <p className="flex-1 text-justify">
                  <b>Emergencias: </b> esta prestación estará cubierta dentro de la red de Prestadores de Servicios de Salud, siempre que dicho servicio sea requerido por el Beneficiario ante ASISTENSI (salvo aquellos casos de estrictas emergencias médicas) para atender accidentes o enfermedades comunes contraídas durante su inclusión en ésta, hasta el límite estipulado en la Póliza de Salud. Las emergencias derivadas de condiciones o enfermedades preexistentes a la entrada o inclusión del Beneficiario no serán cubiertas, conforme lo establecido en las Condiciones Generales del Plan Asistensi contratado.
                  </p>
                </div>
                <p className="text-justify my-2 ml-8">
                La presente cobertura puede incluir sin limitarse, las siguientes prestaciones:
                  <ul className="list-disc my-3 space-y-2 ml-10">
                    <li>Sala de Emergencia</li>
                    <li>Honorarios médicos por emergencia</li>
                    <li>Medicamentos en emergencia <b>(excluyendo los antirretrovirales)</b></li>
                    <li>Material gastable</li>
                    <li>Exámenes de diagnóstico realizados en emergencia</li>
                    <li>Estudios especiales realizados en emergencia</li>
                    <li>Cirugías realizadas en emergencia</li>
                    <li>Anestesia</li>
                    <li>Nebulizador</li>
                    <li>Laboratorios realizados en emergencia</li>
                    <li>Rayos X</li>
                    <li>Sonografías</li>
                    <li>Uso de equipos en emergencia</li>
                  </ul>
                </p>
                <p className="text-justify my-2 ml-8">
                También quedan cubiertos los gastos incurridos por el Beneficiario a raíz de un diagnóstico positivo de Covid-19, siempre que el ASEGURADOR decida mantener su decisión de amparar esta pandemia (excluida de su Condicionado aprobado). Quedan excluido de esta cobertura los gastos relacionados a cualquier prueba diagnóstica realizada para la detección o seguimiento de Covid-19, incluyendo las pruebas PCR, pruebas virales o de anticuerpos en cualquiera de sus variantes. La prueba de Covid-19 PCR será cubierta si el Beneficiario se encuentra hospitalizado y tiene un diagnóstico previo de Covid-19.
                </p>
              </li>
              <li>
                <div className="flex flex-row">
                  <span className="pr-5 ">II.</span>
                  <p className="flex-1 text-justify">
                  <b>Servicios de Hospitalización:</b> bajo este beneficio quedan cubiertos los gastos relacionados con la hospitalización del BENEFICIARIO, siempre que la misma sea realizada en un CENTRO DE SALUD y que la causa que origine dicha hospitalización no esté relacionada con una condición o enfermedad preexistente a la entrada o inclusión del Beneficiario en esta Póliza de Salud. Dicho servicio debe ser autorizado por ASISTENSI al momento de su ingreso y sujeto a la debida pertinencia médica para la prestación del servicio. Ante cualquier EMERGENCIA MÉDICA, el BENEFICIARIO deberá ser atendido inicialmente por ASISTENSI (por vía remota o presencial) y ASISTENSI hará sus mejores esfuerzos para solventar la situación a través de los servicios médicos de ASISTENSI o determinará si se trata de una EMERGENCIA MÉDICA que requiere ser tratada en un CENTRO DE SALUD y autorizará la activación COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD si la EMERGENCIA MÉDICA estuviera cubierta bajo la misma.
                  </p>
                </div>

                <p className="text-justify my-2 ml-8">
                Las hospitalizaciones cubiertas bajo esta Póliza serán las derivadas de una emergencia médica a consecuencia de un accidente o enfermedad común contraídos por el Beneficiario de la Póliza de Salud durante inclusión en ésta. Estos servicios pueden incluir sin limitarse, las siguientes coberturas:
                  <ul className="list-disc my-3 space-y-2 ml-10">
                    <li>Habitación</li>
                    <li>Medicamentos <b className="underline">(excluyendo los antirretrovirales)</b></li>
                    <li>Material gastable</li>
                    <li>Exámenes de diagnóstico</li>
                    <li>Estudios especiales</li>
                    <li>Consultas en internamiento</li>
                    <li>Honorarios médico-quirúrgicos</li>
                    <li>Anestesia</li>
                    <li>Sala de cirugía</li>
                    <li>Unidad de Cuidados Intensivos</li>
                    <li>Terapia respiratoria</li>
                  </ul>
                </p>
                <p className="text-justify my-2 ml-8">
                Los gastos del acompañante no están incluidos dentro de estos gastos.
                </p>
                <p className="text-justify my-2 ml-8">
                Dentro de los servicios de hospitalización quedan cubiertas las intervenciones quirúrgicas derivadas de los casos de emergencia que requieran urgentemente de dicha intervención, siempre que estos sean autorizados por ASISTENSI antes de realizarse la misma. Si durante la hospitalización y a consecuencia de complicaciones debidas a la intervención quirúrgica o por cualquier otra enfermedad no preexistente, el Beneficiario de la Póliza requiere atención médica por parte de uno o varios especialistas distintos al cirujano, además de los honorarios médicos del cirujano, MERCANTIL SEGUROS reconocerá una (1) visita médica diaria para cada uno de los especialistas que intervinieron en la complicación.
                </p>
                <p className="text-justify my-2 ml-8">
                Bajo este servicio de hospitalización también quedan cubiertas las hospitalizaciones derivadas de un diagnóstico positivo de Covid -19 siempre que esta hospitalización haya sido requerida por un profesional de la salud y que dicha hospitalización sea realizada en un CENTRO DE SALUD perteneciente al SISTEMA COORDINADO DE PROVEEDORES, siempre que MERCANTIL SEGUROS decida mantener su decisión de amparar esta pandemia (excluida de su Condicionado aprobado).
                </p>
              </li>
              <li>
                <div className="flex flex-row">
                  <span className="pr-5 ">III.</span>
                  <p className="flex-1 text-justify">
                  <b>Uso de la Unidad de Cuidados Intensivos (UCI):</b> cuando por la naturaleza de la enfermedad o accidente sufrido por el Beneficiario de la Póliza de Salud durante su inclusión en ésta, sea médicamente necesaria el uso de este beneficio, ASISTENSI gestionará la cobertura de este al BENEFICIARIO siempre con autorización de ASISTENSI, hasta el límite estipulado en la Póliza.
                  </p>
                </div>
              </li>
              <li>
                <div className="flex flex-row">
                  <span className="pr-5 ">IV.</span>
                  <p className="flex-1 text-justify">
                    <b>Médicamente Necesarios (Urgencias Médica):</b> Conjunto de medidas o procedimientos ordenados y suministrados por un médico CENTRO DE SALUD, que se ponen en práctica para el tratamiento, curación o  alivio de una estricta emergencia médica, bajo las siguientes características:
                    <ul className="list-decimal my-3 space-y-2 ml-10">
                      <li>Que sea apropiado para el diagnóstico y tratamiento de la enfermedad o lesión del BENEFICIARIO.</li>

                      <li>Que sea congruente con las normas profesionales aceptadas en la práctica de la medicina por la comunidad médica del país donde se presta el servicio o tratamiento.</li>

                      <li>Que el nivel de servicio o suministro sea idóneo y pueda ser proporcionado sin riesgo para el BENEFICIARIO.</li>

                      <li>Que no sea primordialmente para el confort o la conveniencia personal del BENEFICIARIO, de su familia o de su Médico.</li>
                    </ul>
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div  className="mt-20 px-4">
          <p className="text-center my-4 text-lg">
            <b>SECCIÓN II: EXCLUSIONES</b>
          </p>
          <p className="text-justify my-4">
          Esta PÓLIZA no cubre ninguno de los supuestos establecidos en los apartados I. Exclusiones aplicables a la Cobertura de Salud para Emergencias Médicas en Centros de Salud y II. Exclusiones Generales de esta Sección II.
          </p>
          <p id="exclusion1" className="text-left my-4 text-lg">
            <b>I. {" "}  &nbsp;&nbsp;&nbsp; EXCLUSIONES APLICABLES A TODAS LAS COBERTURAS.</b>
          </p>
          <p className="text-justify my-4">
          <b>No serán cubiertos</b> los gastos médicos y/o los siguientes servicios referentes a cualquiera de las Coberturas:
          </p>
          <ul className="my-2 space-y-4 ml-0">
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">1.</span>
                <p className="flex-1 text-justify">
                Medicamentos prescritos por médicos fuera del SISTEMA COORDINADO DE PROVEEDORES (en la “Entrega de Medicamentos Domiciliarios”) sin la visita y verificación de un médico domiciliario o virtual de ASISTENSI.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">2.</span>
                <p className="flex-1 text-justify">
                Servicios de traslado en ambulancia por tratamientos recurrentes y crónicos (ejemplo: diálisis, terapias).
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">3.</span>
                <p className="flex-1 text-justify">
                Tratamientos farmacológicos posteriores a la EMERGENCIA MÉDICA, salvo el servicio de ENTREGA DE MEDICAMENTOS previsto en la presente PÓLIZA.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">4.</span>
                <p className="flex-1 text-justify">
                Servicios de traslados en Aero ambulancia.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">5.</span>
                <p className="flex-1 text-justify">
                Terapia intensiva neonatal.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">6.</span>
                <p className="flex-1 text-justify">
                Personal de salud fijo en el domicilio del BENEFICIARIO.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">7.</span>
                <p className="flex-1 text-justify">
                EMERGENCIAS MÉDICAS ocasionadas como consecuencia de o que se den en el curso de: guerra, invasión, acto de enemigo extranjero, hostilidades u operaciones bélicas (haya habido declaración de guerra o no), insubordinación militar, levantamiento militar, insurrección, rebelión, revolución, guerra civil, poder militar o usurpación de poder, proclamación del estado de excepción, acto de terrorismo o acto de cualquier persona que actúe en nombre de o en relación con alguna organización que realice actividades dirigidas a la sustitución por la fuerza del gobierno o influenciarlo mediante el terrorismo o la violencia.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">8.</span>
                <p className="flex-1 text-justify">
                EMERGENCIAS MÉDICAS producidas por radiación nuclear, fisión, fusión, radiaciones ionizantes o contaminación radioactiva, salvo que se originen por una causa extraña no imputable al BENEFICIARIO.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">9.</span>
                <p className="flex-1 text-justify">
                EMERGENCIAS MÉDICAS causadas directa o indirectamente por eventos catastróficos de origen natural o provocados por el hombre de forma accidental o voluntaria, tales como terremoto, temblor de tierra, maremoto, tsunami, inundación, movimientos de masas, flujos torrenciales, huracanes, tornado, tifón, ciclón, eventos climáticos, granizo, erupción volcánica o cualquier otra convulsión de la naturaleza o perturbación atmosférica.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">10.</span>
                <p className="flex-1 text-justify">
                EMERGENCIAS MÉDICAS consecuencia de la participación activa del BENEFICIARIO en actos delictivos, motín, conmoción civil, disturbios populares, saqueos, disturbios laborales o conflictos laborales (huelgas), revolución asonada, acciones terroristas, secuestro.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">11.</span>
                <p className="flex-1 text-justify">
                Suicidio o sus tentativas, así como las lesiones que el BENEFICIARIO se cause intencionalmente.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">12.</span>
                <p className="flex-1 text-justify">
                EMERGENCIAS MÉDICAS ocasionadas como consecuencia de la participación activa del BENEFICIARIO en duelos o riñas, a menos que se compruebe que no han sido provocados por el BENEFICIARIO o que éste actuó en legítima defensa.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">13.</span>
                <p className="flex-1 text-justify">
                EMERGENCIAS MÉDICAS como consecuencia de la práctica del BENEFICIARIO de los siguientes deportes o actividades de alto riesgo: caza, automovilismo, motociclismo, motocross, karting, scooters, competencia de ciclismo, benji, buceo, submarinismo, esquí acuático, esquí en nieve, rafting, descensos de rápidos, surf, windsurf, remo, pesca en alta mar, pesca submarina, motonáutica, navegación en aguas internacionales en embarcaciones no destinadas al transporte público de pasajeros, fútbol americano, rugby, saltos ornamentales, paracaidismo, vuelo en ícaro, vuelo a vela, vuelo en globo, vuelo en parapente, vuelo en planeador, vuelo en ultraligero, velerismo, vuelo delta, coleo, competencia de equitación, polo, hipismo, rodeo, boxeo, lucha, artes marciales, jiu jitsu, viet vo dao, full contact, competencia de levantamiento de pesas, tiro, espeleología, alpinismo y escalada.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">14.</span>
                <p className="flex-1 text-justify">
                EMERGENCIAS MÉDICAS producto de enfermedades decretadas como epidémicas por el organismo público competente, en lo que se refiere a los gastos que se hayan ocasionado luego de haber sido declaradas como tales, salvo que ASISTENSI, en nombre del ASEGURADOR, comunique lo contrario de manera expresa y explícita para enfermedades específicas.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">15.</span>
                <p className="flex-1 text-justify">
                Tratamientos contra la dependencia del alcohol, de drogas estupefacientes y psicotrópicas y sus complicaciones, agudas o crónicas, el etilismo; así como los accidentes ocurridos bajo la influencia del alcohol o de drogas no prescritas médicamente y las consecuencias y/o enfermedades originadas por el consumo de alcohol o el uso de drogas no prescritas médicamente.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">16.</span>
                <p className="flex-1 text-justify">
                Trasplantes de órganos o tejidos.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">17.</span>
                <p className="flex-1 text-justify">
                EMERGENCIAS MÉDICAS que hayan sido ocasionadas por un trasplante de órgano o tejido.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">18.</span>
                <p className="flex-1 text-justify">
                Tratamientos médicos y/o intervenciones quirúrgicas sobre órganos sanos con fines profilácticos.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">19.</span>
                <p className="flex-1 text-justify">
                Curas de reposo, tratamientos de enfermedades psiquiátricas y mentales, exámenes y terapias efectuadas por médicos psiquiatras, trastornos funcionales de la conducta, neurosis, depresión, psicosis, esquizofrenia, deficiencias mentales, epilepsia, cuadros convulsivos repetitivos sin causa estructural, demencia senil, demencia presenil, trastornos bipolares, Alzheimer y los trastornos del sueño.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">20.</span>
                <p className="flex-1 text-justify">
                Chequeos médicos generales o exámenes con fines de diagnóstico o control, con o sin hospitalización, cuando no haya emergencia médica o no guarden relación con la enfermedad que originó la emergencia médica amparada por la Póliza.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">21.</span>
                <p className="flex-1 text-justify">
                Estados gripales, y aplicación de vacunas.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">22.</span>
                <p className="flex-1 text-justify">
                Acupuntura, medicina naturista, homeopática o cualquier otro tratamiento no aceptado por la Federación Médica Venezolana.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">23.</span>
                <p className="flex-1 text-justify">
                ROCEDIMIENTOS EXPERIMENTALES O INVESTIGATIVOS.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">24.</span>
                <p className="flex-1 text-justify">
                Enfermedades de transmisión sexual, sus consecuencias y complicaciones.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">25.</span>
                <p className="flex-1 text-justify">
                Consultas o exámenes de la vista, corrección de vicios o defectos de refracción visual (miopía, hipermetropía, astigmatismo, presbicia) por tratamientos quirúrgicos o no quirúrgicos, anteojos y/o lentes de contacto, lentes intraoculares o infraoculares.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">26.</span>
                <p className="flex-1 text-justify">
                Tratamiento quirúrgico de patología no tumoral de las glándulas mamarias. Mastoplastia con fines estéticos, funcionales o anatómicos y gigantomastia, Cúmulo de grasa pectoral o lipomastia en hombres.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">27.</span>
                <p className="flex-1 text-justify">
                Tratamientos y controles de la menopausia y la andropausia.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">28.</span>
                <p className="flex-1 text-justify">
                Chequeos y controles ginecológicos.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">29.</span>
                <p className="flex-1 text-justify">
                Tratamientos médicos o intervención quirúrgica cosmética, plástica o estética.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">30.</span>
                <p className="flex-1 text-justify">
                Enfermedades y tratamientos odontológicos y periodontológicos, intervención quirúrgica de distonías miofaciales por mal posición dentaria y/o anomalías de crecimiento de maxilares, tratamientos de cualquier índole de la articulación temporomaxilar, salvo que los mismos sean originados como consecuencia de una emergencia médica que ocurra durante la vigencia del contrato, siempre que estos tratamientos se realicen, como máximo, dentro de los noventa (90) días continuos y siguientes a la fecha de ocurrencia del accidente.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">31.</span>
                <p className="flex-1 text-justify">
                Embarazo, parto, cesárea, aborto y legrado uterino por aborto, sus consecuencias y complicaciones. Consultas y exámenes pre y post natal.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">32.</span>
                <p className="flex-1 text-justify">
                Tratamientos médicos o intervención quirúrgica por disfunciones o insuficiencias sexuales, infertilidad, inseminación artificial, fertilización in vitro, impotencia, Peyronie, frigidez, esterilización, inversión de la esterilización, cambio de sexo y las complicaciones que se deriven de ellos, así como tratamientos anticonceptivos y sus consecuencias.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">33.</span>
                <p className="flex-1 text-justify">
                Tratamientos para la obesidad o reducción de peso.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">34.</span>
                <p className="flex-1 text-justify">
                Estudios, tratamientos y hospitalizaciones por alteraciones hormonales con repercusión en el crecimiento y/o desarrollo.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">35.</span>
                <p className="flex-1 text-justify">
                Tratamientos para el Virus Inmunodeficiencia Humana (VIH) y/o el Síndrome de Inmunodeficiencia Adquirida (SIDA). No obstante, queda a salvo los gastos por emergencias médicas por las enfermedades originadas como consecuencia directa del Síndrome de Inmunodeficiencia Adquirida (SIDA) siempre y cuando el BENEFICIARIO esté en tratamiento para controlar dicha patología.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">36.</span>
                <p className="flex-1 text-justify">
                Honorarios como consecuencia de cualquier tratamiento, proporcionado por un médico o enfermera que tenga parentesco con el BENEFICIARIO, dentro del segundo grado de consanguinidad o afinidad, o que viva con éstos.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">37.</span>
                <p className="flex-1 text-justify">
                Impuestos, gastos de cobranza, limpieza, microfilm, historias clínicas, estacionamiento, misceláneos y otros costos no definidos ni relacionados con el tratamiento de la afección declarada.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">38.</span>
                <p className="flex-1 text-justify">
                Tratamiento médico, intervención quirúrgica, servicios hospitalarios, procedimiento médico, medicamentos, suministros, equipos e instrumentos especiales, cuyas facturas no cumplan con las exigencias del Servicio Nacional Integrado de Administración Aduanera y Tributaria (SENIAT).
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">39.</span>
                <p className="flex-1 text-justify">
                La adquisición de los siguientes equipos médicos utilizados para controles de salud y/o prevención: tensiómetro, glucómetro, cepillos dentales, humidificador, irrigador dental, colchón de aire anti escaras y equipos de resucitación.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">40.</span>
                <p className="flex-1 text-justify">
                  Gastos médicos que no sean consecuencia de una emergencia médica del BENEFICIARIO.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">41.</span>
                <p className="flex-1 text-justify">
                Cualquier ENFERMEDAD PREEXISTENTE o ENFERMEDAD CONGÉNITA que pueda padecer al momento de CONTRATACIÓN del PLAN, que sea de conocimiento o no del BENEFICIARIO, y sus posibles complicaciones médicas, no serán cubiertas por el alcance de la PÓLIZA para la COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row font-bold ">
                <span className="pr-5 ">42.</span>
                <p className="flex-1 text-justify">
                El ASEGURADOR tampoco asumirá ninguna de las coberturas de la póliza en los siguientes supuestos:
                    <ul className="my-2 space-y-2 ml-3">
                      <li className="flex flex-row ">
                        <span className="pr-5 ">a.</span>
                        <p className="flex-1 text-justify">
                        Cuando la emergencia es originada por acciones criminales o dolosas del TOMADOR o del BENEFICIARIO.
                        </p>
                      </li>
                      <li className="flex flex-row ">
                        <span className="pr-5 ">b.</span>
                        <p className="flex-1 text-justify">
                        Cuando la EMERGENCIA MÉDICA o Accidente sea originada intencionalmente por el Beneficiario, sus familiares o personas que convivan con él.
                        </p>
                      </li>
                      <li className="flex flex-row ">
                        <span className="pr-5 ">c.</span>
                        <p className="flex-1 text-justify">
                        Si el BENEFICIARIO suministra información falsa o inexacta u omitiere cualquier dato, que de haber sido conocido por ASISTENSI, éste no hubiese otorgado la cobertura o no lo hubiese hecho en las mismas condiciones.
                        </p>
                      </li>
                      <li className="flex flex-row ">
                        <span className="pr-5 ">d.</span>
                        <p className="flex-1 text-justify">
                        Si el TOMADOR, el BENEFICIARIO o cualquier persona que obre por cuenta de éstos, presenta una reclamación fraudulenta o engañosa, o si en cualquier tiempo emplea medios o documentos engañosos o dolosos para sustentar una reclamación o para derivar otros beneficios relacionados con esta Póliza
                        </p>
                      </li>
                      <li className="flex flex-row ">
                        <span className="pr-5 ">e.</span>
                        <p className="flex-1 text-justify">
                        Si el siniestro ha sido ocasionado por culpa grave del Tomador o del Beneficiario salvo si el siniestro ha sido ocasionado en cumplimiento de deberes legales de socorro o en tutela de intereses comunes con ASISTENSI y/o ACUNSA, en lo que respecta al PLAN.
                          </p>
                      </li>
                      <li className="flex flex-row ">
                        <span className="pr-5 ">f.</span>
                        <p className="flex-1 text-justify">
                        Si el siniestro se inicia antes de la vigencia del contrato, o durante los plazos de espera, y continúa después de que los riesgos hayan comenzado a correr por cuenta del Asegurador.
                        </p>
                      </li>
                      <li className="flex flex-row ">
                        <span className="pr-5 ">g.</span>
                        <p className="flex-1 text-justify">
                        Si el Tomador o el Beneficiario no empleare los medios a su alcance para aminorar las consecuencias del siniestro, siempre que este incumplimiento se produjera con la manifiesta intención de perjudicar o engañar al Asegurador.
                        </p>
                      </li>
                      <li className="flex flex-row ">
                        <span className="pr-5 ">h.</span>
                        <p className="flex-1 text-justify">
                        Si el Tomador o el Beneficiario o cualquier persona que obre por cuenta de éstos, actuando con dolo o culpa grave, obstaculiza los derechos del Asegurador estipulados en este PLAN.
                        </p>
                      </li>
                      <li className="flex flex-row ">
                        <span className="pr-5 ">i.</span>
                        <p className="flex-1 text-justify">
                        Si el Tomador o el Beneficiario no siguen el Proceso de Utilización de los Servicios descrito en el Artículo 7 de la PÓLIZA.
                        </p>
                      </li>
                    </ul>
                </p>
              </div>
            </li>
          </ul>
        </div>

        <div className="mt-10 ">
          <p className="my-3 text-center">
            <b>Apendice 3.1.3 <br/> INVENTARIO DOMICILIARIO</b>
          </p>
          <div className="my-2 ml-0 text-center w-full overflow-x-scroll sm:overflow-x-auto">
            <table className="border-2 border-gray-800 w-full text-xs md:text-base">
              <thead className="border-2 border-gray-800 ">
                <tr>
                  <th className="text-center">PRESENTACIÓN</th>
                  <th className="text-center">TIPO</th>
                  <th className="text-center">PRINCIPIO ACTIVO</th>
                </tr>
              </thead>
              <tbody>
                <tr> <td>TABLETA</td> <td>ANTIHISTAMINICO</td> <td>DESLORATADINA</td> </tr>
                <tr> <td>TABLETA</td> <td>ANTIHISTAMINICO</td> <td>CETIRIZINA</td> </tr>
                <tr> <td>TABLETA</td> <td>ANTIHISTAMINICO</td> <td>FEXOFENADINA</td> </tr>
                <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>SECNIDAZOL</td> </tr>
                <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>NITOZAXONIDA</td> </tr>
                <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>METRONIDAZOL</td> </tr>
                <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>CEFADROXILO</td> </tr>
                <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>CEFADROXILO SUSP.</td> </tr>
                <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>LEVOFLOXACINA</td> </tr>
                <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>CIPROFLOXACINA</td> </tr>
                <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>SULTAMICILINA</td> </tr>
                <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>MOXIFLOXACINA</td> </tr>
                <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>ACICLOVIR</td> </tr>
                <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>FLUCONAZOL</td> </tr>
                <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>AZITROMICINA</td> </tr>
                <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>AMOXICILINA</td> </tr>
                <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>AMOXICILINA + ACIDO CLAVULÁNICO</td> </tr>
                <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>MACRODANTINA</td> </tr>
                <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>TRIMETOPRIM/SULFA</td> </tr>
                <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>CEFTIBUTEN</td> </tr>
                <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>ALBENDAZOL</td> </tr>
                <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>BACITRACINA</td> </tr>
                <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>MUPIROCINA</td> </tr>
                <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>NITROFURAZONA</td> </tr>
                <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>CLORTRIMAZOL</td> </tr>
                <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>KETOCONAZOL</td> </tr>
                <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>CLOTRIMAZOL/NEOMICINA/DEXAM</td> </tr>
                <tr> <td>TABLETA</td> <td>DIGESTIVO</td> <td>RANITIDINA</td> </tr>
                <tr> <td>TABLETA</td> <td>DIGESTIVO</td> <td>ESOMEPRAZO</td> </tr>
                <tr> <td>TABLETA</td> <td>DIGESTIVO</td> <td>OMEPRAZOL</td> </tr>
                <tr> <td>TABLETA</td> <td>DIGESTIVO</td> <td>PANTOPRAZOL</td> </tr>
                <tr> <td>TABLETA</td> <td>DIGESTIVO</td> <td>ANTIÁCIDO</td> </tr>
                <tr> <td>TABLETA</td> <td>DIGESTIVO</td> <td>METOCLOPRAMIDA</td> </tr>
                <tr> <td>TABLETA</td> <td>DIGESTIVO</td> <td>LOPERAMIDA</td> </tr>
                <tr> <td>TABLETA</td> <td>ANALG/ANTIINF/ANTIP</td> <td>ACETAMINOFEN</td> </tr>
                <tr> <td>TABLETA</td> <td>ANALG/ANTIINF/ANTIP</td> <td>ACETAMINOFEN/CLORFENIRAMINA</td> </tr>
                <tr> <td>TABLETA</td> <td>ANALG/ANTIINF/ANTIP</td> <td>IBUPROFENO</td> </tr>
                <tr> <td>TABLETA</td> <td>ANALG/ANTIINF/ANTIP</td> <td>TIOCOLCHICOSIDO</td> </tr>
                <tr> <td>TABLETA</td> <td>ANALG/ANTIINF/ANTIP</td> <td>KETOROLAC</td> </tr>
                <tr> <td>TABLETA</td> <td>ANALG/ANTIINF/ANTIP</td> <td>DICLOFENAC</td> </tr>
                <tr> <td>TABLETA</td> <td>ANALG/ANTIINF/ANTIP</td> <td>DICLOFENAC POTASICO</td> </tr>
                <tr> <td>TABLETA</td> <td>ANALG/ANTIINF/ANTIP</td> <td>GENURIN</td> </tr>
                <tr> <td>TABLETA</td> <td>ANALG/ANTIINF/ANTIP</td> <td>MELOXICAM</td> </tr>
                <tr> <td>TABLETA</td> <td>CARDIOVASCULAR</td> <td>CLOPIDOGREL</td> </tr>
                <tr> <td>TABLETA</td> <td>CARDIOVASCULAR</td> <td>PROPRANOLOL</td> </tr>
                <tr> <td>TABLETA</td> <td>CARDIOVASCULAR</td> <td>FUROSEMIDA</td> </tr>
                <tr> <td>TABLETA</td> <td>CARDIOVASCULAR</td> <td>WARFARINA</td> </tr>
                <tr> <td>TABLETA</td> <td>CARDIOVASCULAR</td> <td>PENTOXIFILINA</td> </tr>
                <tr> <td>TABLETA</td> <td>CARDIOVASCULAR</td> <td>ASPIRINA</td> </tr>
                <tr> <td>TABLETA</td> <td>CARDIOVASCULAR</td> <td>DILTIAZEM</td> </tr>
                <tr> <td>TABLETA</td> <td>VARIOS</td> <td>COMPLEJO B</td> </tr>
                <tr> <td>TABLETA</td> <td>VARIOS</td> <td>DIFENHIDRAMINA</td> </tr>
                <tr> <td>TABLETA</td> <td>VARIOS</td> <td>TIAMAZOL 5 MG</td> </tr>
                <tr> <td>TABLETA</td> <td>VARIOS</td> <td>VITAMINA C</td> </tr>
                <tr> <td>TABLETA</td> <td>VARIOS</td> <td>BETAHISTINA</td> </tr>
                <tr> <td>TABLETA</td> <td>VARIOS</td> <td>PREDNISONA</td> </tr>
                <tr> <td>TABLETA</td> <td>VARIOS</td> <td>AMBROXOL</td> </tr>
                <tr> <td>TABLETA</td> <td>VARIOS</td> <td>SUERO ORAL</td> </tr>
                <tr> <td>TABLETA</td> <td>VARIOS</td> <td>BETAMETASONA</td> </tr>
                <tr> <td>TABLETA</td> <td>VARIOS</td> <td>HEPARINA</td> </tr>
                <tr> <td>TABLETA</td> <td>OTICAS</td> <td>POLIOTICO</td> </tr>
                <tr> <td>TABLETA</td> <td>OTICAS</td> <td>CIPROFLOXACINA</td> </tr>
                <tr> <td>TABLETA</td> <td>OTICAS</td> <td>CIPROFLOX + HIDROCORT</td> </tr>
                <tr> <td>TABLETA</td> <td>OCULARES</td> <td>DEXTRAN</td> </tr>
                <tr> <td>TABLETA</td> <td>OCULARES</td> <td>DEXAM + TOBRAMICINA</td> </tr>
                <tr> <td>TABLETA</td> <td>OCULARES</td> <td>DICLOFENAC + TOBRAMICINA</td> </tr>
                <tr> <td>TABLETA</td> <td>OCULARES</td> <td>COLIRIO</td> </tr>
                <tr> <td>TABLETA</td> <td>OCULARES</td> <td>PREDNISOLONA</td> </tr>
                <tr> <td>TABLETA</td> <td>OCULARES</td> <td>ANTIHISTAMÍNICO</td> </tr>
                <tr> <td>TABLETA</td> <td>OCULARES</td> <td>OLOPATADINA</td> </tr>
                <tr> <td>TABLETA</td> <td>OCULARES</td> <td>TOBRAMICINA + DEXAM</td> </tr>
                <tr> <td>TABLETA</td> <td>NASALES</td> <td>RINOMAX</td> </tr>
                <tr> <td>TABLETA</td> <td>NASALES</td> <td>BUDESONIDA</td> </tr>
                <tr> <td>TABLETA</td> <td>NASALES</td> <td>OXIMETAZOLINA</td> </tr>
                <tr> <td>TABLETA</td> <td>NASALES</td> <td>SOLUCIÓN FISIOLÓGICA</td> </tr>
                <tr> <td>TABLETA</td> <td>INHALADOR</td> <td>IPATROPIO INHALADOR</td> </tr>
                <tr> <td>TABLETA</td> <td>INHALADOR</td> <td>SALBUTAMOL</td> </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="mt-10 ">
          <p className="my-3 text-center">
            <b>Apéndice 3.2 al Anexo A <br/> Alcance de INVENTARIO CRÍTICO por patologías</b>
          </p>
          <p className="my-4 text-justify">
            <b>Patología Cardiovascular: </b>Todas aquellas que afecten el normal funcionamiento del corazón y los vasos sanguíneos (infarto, crisis hipertensiva, insuficiencia cardíaca descompensada).
          </p>
          <p className="my-4 text-justify">
            <b>Patología Respiratoria: </b>Todas las Enfermedades o afecciones que alteran el normal funcionamiento del aparato respiratorio y por lo tanto afectan la función respiratoria normal del paciente (crisis de asma, exacerbación de EPOC, insuficiencia respiratoria).
          </p>
          <p className="my-4 text-justify">
            <b>Patología Neurológica: </b>Todas aquellas alteraciones que afecten tanto el sistema nervioso central como el periférico, en especial a nivel cerebral (accidente cerebrovascular, convulsiones, epilepsia, traumatismos de cráneo).
          </p>
          <p className="my-4 text-justify">
            <b>Patología Gineco-Obstétrica: </b>Las afecciones que alteran el aparato reproductor femenino así como también la atención de la gestante en caso de complicaciones o durante el nacimiento del recién nacido.
          </p>
          <p className="my-4 text-justify"> <b>Patología Urológica: </b>Todas las alteraciones que se presenten en la vía urinaria (desde riñones hasta uretra) tanto del hombre como de la mujer tales como nefrolitiasis, infecciones urinarias, cólicos renales, problemas de próstata y retención aguda de orina.
          </p>
          <p className="my-4 text-justify">
            <b>Patología Traumatológica: </b>Todas aquellas alteraciones traumáticas donde exista solución de continuidad de algún hueso, así como las alteraciones en ligamentos, músculos y tendones. En esta clasificación se encuentran las fracturas, esguinces, y luxaciones.
          </p>
          <p className="my-4 text-justify">
            <b>Patología Quirúrgica: </b>Todas aquellas afecciones que requieren de solución quirúrgica precoz como apendicitis aguda, colecistitis, así como también procedimientos quirúrgicos programados y ambulatorios.
          </p>
          <p className="my-4 text-justify">
            <b>Patología Infecciosa: </b>Todas las Enfermedades infecciosas (En especial bacterianas) que pueden afectar a la persona. De acuerdo a la localización y sistema afectado puede tratarse de infecciones respiratorias, de vías digestivas, genitourinarias, de piel y partes blandas, abdominales y neurológicas.
          </p>
          <p className="my-4 text-justify">
            <b>Patología Dermatológica: </b>Todas las alteraciones del tejido de piel y partes blandas lesionadas por concepto de heridas, quemaduras o infección.
          </p>
        </div>

        <div className="mt-10 ">
          <p className="my-3 text-center">
            <b>Apendice 3.2.2<br/> INVENTARIO CRÍTICO</b>
          </p>
          <div className="my-2 ml-0 text-center w-full overflow-x-scroll sm:overflow-x-auto">
            <table className="border-2 border-gray-800 w-full text-xs md:text-base">
              <thead className="border-2 border-gray-800 ">
                <tr>
                  <th className="text-center">TIPO</th>
                  <th className="text-center">ITEM</th>
                </tr>
              </thead>
              <tbody>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>KIT DE OBSTETRICIA</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>CATETER HEMODIALISIS</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>VASOS HUMIDIFICADORES DE OXIGENO</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>GASA ESTERIL</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>EQUIPO INFUSION MACROGOTERO</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>EQUIPO INFUSION MICROGOTERO</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>TUBOS EXTENSION K50</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>SCALP 21G Y 23G</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>CATETER YELCO 14G A 24G</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>OBTURADOR</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>LLAVE 3 VIAS</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>MASCARILLA NEBULIZAR ADULTO</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>MASCARILLA NEBULIZAR PEDIATRICA</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>MASCARILLA SIMPLES ADULTO</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>MASCARILLA SIMPLES PEDIATRICA</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>BIGOTERA OXIGENO</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>KIT LAPAROTOMIA</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>KIT PUNCION LUMBAR</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>BATA DE PACIENTE</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>MONO CIRUJANO</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>SABANAS CAMILLA</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>CENTRO DE CAMA</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>CUBREBOTAS</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>GORROS DESCARTABLES</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>MASCARILLA 4 TIRAS</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>ADHESIVO MICROPORE/DURAPORE</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>TEGADERM 10X12</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>AGUJAS 21G</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>INYECTADORA 1CC</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>INYECTADORA 3CC</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>INYECTADORA 5CC</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>INYECTADORA 10CC</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>INYECTADORA 20CC</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>INYECTADORA 60CC</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>GUANTES EXAMEN (CAJA)</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>GUANTES ESTERILES</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>AGUA OXIGENADA 1 LITRO</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>ALCOHOL 1 LITRO</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>YODO SOLUCION (GALON)</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>DESINFECTANTE (GALON)</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>ALGODÓN 500 GR</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>SUTURA NYLON</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>SUTURA CROMICO</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>SUTURA VICRYL</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>COMPRESAS LAPAROTOMÍA</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>CUENTA GOTAS</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>HYPAFIX ROLLO</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>STERI-STRIP</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>HOJILLA BISTURI</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>KIT CESAREA</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>KIT ARTROSCOPIA</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>RESPIRADOR TIPO AMBU ADULTO</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>RESPIRADOR TIPO AMBU PEDIATRICO</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>ELECTRODOS</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>TUBO ENDOTRAQUEAL (DISTINTOS TAMAÑOS)</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>CANULA DE MAYO (DISTINTOS TAMAÑOS)</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>MASCARA LARINGEA (DISTINTOS TAMAÑOS)</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>MASCARA ANESTESIA</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>CANULA DE YANKAUER</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>T DE AYRES</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>SONDA VESICAL LATEX (DISTINTOS TAMAÑOS)</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>SONDA VESICAL SILICONE (DISTINTOS TAMAÑOS)</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>BOLSAS RECOLECTORAS DE ORINA</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>KIT VIA CENTRAL</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>MEDIDOR DE PVC</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>VENDAS ELASTICAS 7.5</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>VENDAS ELASTICAS 15</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>VENDAS TIPO COBAN</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>VENDAS DE YESO</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>WATTA</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>SONDA NASOGASTRICA (DISTINTOS TAMAÑOS)</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>STOKINET</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>CURITAS REDONDAS X100</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>CURITAS ESTÁNDAR</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>KIT DE CONVENIENCIA</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>BAJALENGUA</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>TUBOS LABORATORIO ROJO</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>TUBOS LABORATORIO MORADO</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>TUBOS LABORATORIO AZULES</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>APOSITOS ADHESIVOS 6 X 10 CTM</td> </tr>
                <tr> <td>MATERIAL MEDICO-QX</td> <td>CATETER ASPIRACION CIRCUITO CERRADO</td> </tr>
                <tr> <td>SOLUCIONES PARENTERALES</td> <td>SOLUCION 0.9%</td> </tr>
                <tr> <td>SOLUCIONES PARENTERALES</td> <td>SOLUCION 0.45%</td> </tr>
                <tr> <td>SOLUCIONES PARENTERALES</td> <td>SOLUCION DEXTTROSA 5%</td> </tr>
                <tr> <td>SOLUCIONES PARENTERALES</td> <td>SOLUCION RINGER LACTATO</td> </tr>
                <tr> <td>SOLUCIONES PARENTERALES</td> <td>SOLUCION KCL 7.5%</td> </tr>
                <tr> <td>SOLUCIONES PARENTERALES</td> <td>BICARBONATO DE SODIO FRASCO</td> </tr>
                <tr> <td>SOLUCIONES PARENTERALES</td> <td>SULFATO DE MAGNESIO</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>RANITIDINA AMP</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>KETOPROFENO IM</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>DICLOFENAC</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>TIOCOLCHICOSIDO</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>BROMURO DE HIOSH AMP</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>DEXAMETASONA AMP</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>METILPREDNISOLINA AMP</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>ADRENALINA</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>ATROPINA</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>CLORFERINAMINA</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>FUROSEMIDA</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>METOCLOPRAMIDA</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>DICLOFENAC</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>PROPOFOL AMP</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>LIDOCAINA AMP</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>OMEPRAZOL/ESOMEPRAZOL AMPOLLA</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>OXITOCINA AMPOLLA</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>METHERGIN AMPOLLA</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>DIAZEPAM AMPOLLA</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>FENITOINA AMPOLLA</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>AMIKACINA AMP.</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>CEFTRIAXONE AMP</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>MEROPENEM AMP</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>CIPROFLOXAINA AMP</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>LEVOFLOXACINA</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>MOXIFLOXACINA AMP</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>CLINDAMICINA AMP</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>VANCOMICINA AMP</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>METRONIDAZOL AMP</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>AMPICILINA/SULBACTAM AMP</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>FLUCONAZOL AMP</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>MIDAZOLAM</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>ATENOLOL AMP</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>DIGOXINA AMP</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>DOPAMINA</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>NORADRENALINA</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>AMIODARONA</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>VERAPAMIL</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>TRAMADOL O MORFINA</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>NIMOTOP</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>BUDESONIDA SOLUCION PARA INHALAR</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>IPATROPIO SOLUCION PARA INHALAR</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>SALBUTAMOL SOLUCIÖN PARA INHALAR</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>HEPARINA SODICA</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>ASPIRINA TABLETAS</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>CLOPIDOGREL TABLETAS</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>ENOXAPARINA AMPOLLA 60 MG</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>ENOXAPARINA AMPOLLA 40 MG</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>CAPTOPRIL TABLETAS</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>CLONIDINA AMPOLLA</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>NITROPRUSIATO AMPOLLA</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>NITROGLICERINA AMPOLLA</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>INSULINA CRISTALINA FRASCO</td> </tr>
                <tr> <td>MEDICAMENTO</td> <td>INSULINA NPH FRASCO</td> </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div  className="mt-20 px-4">
          <p className="text-center my-4 text-lg">
            <b>ANEXO B – BOTÓN DE ASISTENCIA</b>
          </p>
          <p className="text-justify my-4">
          <b>En el presente Anexo se regula el servicio “Botón de Asistencia”, comercializado por ACUNSA. Este servicio no es una cobertura aseguradora prestada por ACUNSA, sino un servicio prestado conjuntamente por ASISTENSI GLOBAL INSURANCE INC, sociedad comercial incorporada de conformidad con las leyes de Barbados y su subsidiaria en Venezuela, ASISTENSI VENEZUELA, C.A. en adelante denominados “ASISTENSI”.</b>
          </p>
          <p className="text-justify my-4">
          El servicio “Botón de Asistencia” queda incorporado a la presente PÓLIZA por haber sido contratado por usted. (“<b>USUARIO</b>”). Este anexo contiene los términos y condiciones del dispositivo de botón de asistencia en adelante denominado el “<b>DISPOSITIVO</b>” como un servicio adicional prestado por ASISTENSI.
          </p>
          <p className="text-justify my-4">
          Por favor, lea atentamente el presente documento antes de contratar y utilizar el DISPOSITIVO. El USUARIO dispondrá de un período de quince (15) días (“PERIODO DE PRUEBA”) desde la recepción del DISPOSITIVO para realizar la devolución de este en caso de que no esté conforme. Una vez superado el <b>PERÍODO DE PRUEBA</b>, si la devolución del “DISPOSITIVO” no fue llevada a cabo por el USUARIO.
          </p>
          <ul className="my-2 space-y-4 ml-0">
              <li>
                <div className="flex flex-row font-bold ">
                  <span className="pr-5 ">1.</span>
                  <p className="flex-1 text-justify">
                  Definiciones y conceptos
                  </p>
                </div>
                <p className="text-justify my-2 ml-9">
                Los términos que no se definan en el presente CONTRATO ANEXO BOTÓN DE ASISTENCIA tendrán el significado previsto en el PLAN ASISTENSI.
                </p>
                <ul className="my-2 space-y-2 ml-8 mt-5">
                  <li className="flex flex-row ">
                    <span className="pr-5 font-bold ">1.1.</span>
                    <p className="flex-1 text-justify">
                    <b>ACCESO A LOS SERVICIOS:</b> ASISTENSI se obliga a incluir a los BENEFICIARIOS de la Póliza en los servicios de asistencia mediante el DISPOSITIVO siempre y cuando el BENEFICIARIO cumpla con los requisitos de elegibilidad del PLAN ASISTENSI para contratar dicho dispositivo y el TOMADOR DEL PLAN cumpla con los términos y condiciones del PLAN ASISTENSI y del presente ANEXO DE BOTÓN ASISTENCIA.
                    </p>
                  </li>
                  <li className="flex flex-row ">
                    <span className="pr-5 font-bold ">1.2.</span>
                    <p className="flex-1 text-justify">
                    <b>ÁMBITO TERRITORIAL:</b> Territorio de la República Bolivariana de Venezuela.
                    </p>
                  </li>
                  <li className="flex flex-row ">
                    <span className="pr-5 font-bold ">1.3.</span>
                    <p className="flex-1 text-justify">
                    <b>DISPOSITIVO:</b> Dispositivo de teleasistencia médica adicional y opcional para personas que hayan contratado el PLAN ASISTENSI que sirve para contactar con ASISTENSI y cuyo sistema es geolocalizable para que ASISTENSI preste servicios en casos de emergencia.
                    </p>
                  </li>
                  <li className="flex flex-row ">
                    <span className="pr-5 font-bold ">1.4.</span>
                    <p className="flex-1 text-justify">
                    <b>GARANTÍA Y DEFECTOS:</b> El DISPOSITIVO está garantizado por defectos de materiales o de fabricación durante un lapso de dos (2) años a partir de la fecha de contratación del servicio. En este periodo ASISTENSI puede, según su criterio, reparar o sustituir cualquier componente que no funcione correctamente sin coste alguno para el USUARIO en concepto de piezas o mano de obra y envío. Esta garantía limitada no se aplica a: (i) los daños estéticos, tales como arañazos, marcas o abolladuras; (ii) las piezas consumibles, tales como las pilas/baterías; (iii) los daños provocados por accidentes, uso inadecuado, el agua, inundaciones, fuego, otras causas de fuerza mayor o causas externas; (iv) los daños provocados por reparaciones realizadas por un proveedor de servicio no autorizado por ASISTENSI; o (v) los daños ocurridos en productos que hayan sido modificados o alterados sin la autorización por escrito de ASISTENSI; o (vi) los daños ocurridos en productos conectados a la fuente de alimentación. Además, ASISTENSI se reserva el derecho a rechazar reclamaciones de garantía de productos o servicios. Los DISPOSITIVOS reparados o sustituidos tienen una garantía igual a la del periodo de garantía original, es decir, de dos (2) años desde la entrega del DISPOSITIVO reparado o sustituido. Si desea notificar de algún defecto en su DISPOSITIVO puede hacerlo a través del teléfono +58 212.8221260.
                    </p>
                  </li>
                  <li className="flex flex-row ">
                    <span className="pr-5 font-bold ">1.5.</span>
                    <p className="flex-1 text-justify">
                    <b>HORARIO:</b> El servicio estará a disponibilidad del USUARIO, las veinticuatro (24) horas del día, los trescientos sesenta y cinco (365) días del año, salvo fuerza mayor en el ÁMBITO TERRITORIAL, o en el operador de telefonía móvil que provee las comunicaciones del DISPOSITIVO, que impidan mantener el servicio de manera excepcional y temporal.
                    </p>
                  </li>
                  <li className="flex flex-row ">
                    <span className="pr-5 font-bold ">1.6.</span>
                    <p className="flex-1 text-justify">
                    <b>PERIODO DE CONTRATACION:</b> Periodo de un (1) año, prorrogable anualmente, durante el cual el DISPOSITIVO se encuentra en uso y disfrute por el USUARIO. Antes de que se finalice el PERÍODO DE CONTRATACIÓN, en caso de que el USUARIO no desee continuar con la prestación del servicio, deberá notificar ASISTENSI para recibir las instrucciones de devolución del DISPOSITIVO. El USUARIO será penalizado con $30 USD (dólares americanos) que deberá abonar a ASISTENSI en caso de imposibilidad material para devolver el DISPOSITIVO o por daño del mismo.
                    </p>
                  </li>
                  <li className="flex flex-row ">
                    <span className="pr-5 font-bold ">1.7.</span>
                    <p className="flex-1 text-justify">
                    <b>PERIODO DE PRUEBA:</b> Se refiere al periodo de quince (15) días calendario desde la entrega del DISPOSITIVO, durante los cuales el USUARIO podrá devolverlo en caso de disconformidad, debiendo estar el DISPOSITIVO en las mismas condiciones en las que se le entregó. En caso contrario, el USUARIO será penalizado con $30 USD (dólares americanos) que deberá abonar a ASISTENSI en caso de imposibilidad material para devolver el DISPOSITIVO o por daño del mismo.
                    </p>
                  </li>
                  <li className="flex flex-row ">
                    <span className="pr-5 font-bold ">1.8.</span>
                    <p className="flex-1 text-justify">
                    <b>USUARIO:</b> BENEFICIARIO del PLAN ASISTENSI que ha contratado el servicio del DISPOSITIVO.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <div className="flex flex-row font-bold ">
                  <span className="pr-5 ">2.</span>
                  <p className="flex-1 text-justify">
                  Objeto del contrato
                  </p>
                </div>
                <p className="text-justify my-2 ml-12">
                Entrega por parte de ASISTENSI del DISPOSITIVO con el fin de que el USUARIO pueda comunicarse con ASISTENSI de manera inmediata, en caso de presentarse una emergencia médica o situación médica de fase aguda que amerite una solicitud de servicio, mediante la pulsación de un botón.
                </p>
                <p className="text-justify my-2 ml-12">
                En caso de que, por cualquier motivo, no fuera posible que el USUARIO se comunique a través del DISPOSITIVO, el USUARIO también podrá utilizar el resto de vías regulares de comunicación con ASISTENSI (vía telefónica al +58 212.8221260; vía página web www.asistensi.com.ve; o vía aplicativo móvil disponible en las tiendas de APP iOS y Android llamado “asistensi”), indicando su número de identificación cédula de identidad o pasaporte, su nombre, apellido y motivo de la llamada.
                </p>
                <p className="text-justify my-2 ml-12">
                La propiedad del DISPOSITIVO es de ASISTENSI y, en ningún caso, se entenderá como propiedad del USUARIO, a pesar de la entrega de la posesión del DISPOSITIVO al momento de la contratación. No obstante lo anterior, el USUARIO responderá de la pérdida o daño del DISPOSITIVO por un importe de $30 USD (dólares americanos).
                </p>
              </li>
              <li>
                <div className="flex flex-row font-bold ">
                  <span className="pr-5 ">3.</span>
                  <p className="flex-1 text-justify">
                  Proceso de utilización de los servicios
                  </p>
                </div>
                <p className="text-justify my-2 ml-12">
                Durante la contratación del PLAN ASISTENSI por el USUARIO (BENEFICIARIO) éste podrá optar por contratar el DISPOSITIVO. Una vez contratado el servicio, el USUARIO recibirá en su domicilio el DISPOSITIVO para su uso y disfrute con la correspondiente contraprestación por dicho servicio.
                </p>
              </li>
              <li>
                <div className="flex flex-row font-bold ">
                  <span className="pr-5 ">4.</span>
                  <p className="flex-1 text-justify">
                  Protección de datos
                  </p>
                </div>
                <p className="text-justify my-2 ml-12">
                Con el fin de proporcionar los servicios incluidos en el presente Anexo en relación con el DISPOSITIVO, ASISTENSI precisa tratar ciertos datos personales adicionales a los recogidos con motivo de la contratación de la póliza principal. Al pulsar el botón incluido en el DISPOSITIVO, ASISTENSI geolocalizará la ubicación del dispositivo en ese momento, así como la presión arterial y la temperatura corporal del USUARIO en el momento de la solicitud del servicio. Estos datos son precisos para proporcionar el servicio de asistencia solicitado y solo serán recopilados y utilizados en el momento de la solicitud y únicamente para atender la solicitud del USUARIO. Por lo tanto, el USUARIO otorga su consentimiento explícito a ASISTENSI para poder tratar los datos personales mencionados.
                </p>
                <p className="text-justify my-2 ml-12">
                Los datos serán conservados mientras sean necesarios para la prestación del servicio solicitado, siendo eliminados una vez el servicio solicitado ha sido prestado adecuadamente.
                </p>
                <p className="text-justify my-2 ml-12">
                Para más información sobre nuestras prácticas de privacidad visite la política de privacidad incluida junto con los términos y condiciones del PLAN ASISTENSI.
                </p>
              </li>
              <li>
                <div className="flex flex-row font-bold ">
                  <span className="pr-5 ">5.</span>
                  <p className="flex-1 text-justify">
                  Exoneración de responsabilidad
                  </p>
                </div>
                <p className="text-justify my-2 ml-12">
                ASISTENSI no se hará responsable bajo ningún concepto y en ningún caso, de los daños imprevistos, especiales, indirectos o emergentes, tanto si son resultado de la utilización, mala utilización o desconocimiento del manejo de este producto, como si lo son por defectos en este. A su vez, ASISTENSI no se hará responsable por la falta de batería del DISPOSITIVO, o falta de señal por causa del operador de telefonía móvil.
                </p>
                <p className="text-justify my-2 ml-12">
                El DISPOSITIVO, es una herramienta para facilitar al USUARIO el acceso a los servicios del PLAN ASISTENSI. En consecuencia, el DISPOSITIVO no constituye una vía de acceso exclusiva a los servicios, ni sustituye el alcance contratado y definido en los Términos y Condiciones del PLAN ASISTENSI. ASISTENSI tampoco se hace responsable de eventos de fuerza mayor, nacionales o regionales, que afecten las comunicaciones del país de manera excepcional que impidan atender el servicio en tiempos oportunos.
                </p>
             </li>
              <li>
                <div className="flex flex-row font-bold ">
                  <span className="pr-5 ">6.</span>
                  <p className="flex-1 text-justify">
                  Modificaciones a los términos y condiciones
                  </p>
                </div>
                <p className="text-justify my-2 ml-12">
                ASISTENSI podrá realizar cualquier modificación a los presentes TÉRMINOS Y CONDICIONES y notificará al TOMADOR DEL PLAN de dicha modificación en un periodo de diez (10) días hábiles mediante la notificación mediante correo electrónico a los TOMADORES DEL PLAN, a fin de que éstos tengan la oportunidad de manifestar si desean continuar bajo el nuevo clausulado o terminar la relación contractual, para sí y los correspondientes BENEFICIARIOS. Se entenderá como aceptación de los nuevos TÉRMINOS Y CONDICIONES, (i) la aceptación expresa por parte del TOMADOR DEL PLAN; (ii) el silencio del TOMADOR DEL PLAN transcurridos diez (10) días desde la notificación de la modificación de los TÉRMINOS Y CONDICIONES; y (iii) el uso continuado por parte del BENEFICIARIO de los servicios, después de recibir la notificación sobre los cambios en los TÉRMINOS Y CONDICIONES. Para más información sobre el PLAN ASISTENSI por favor consulte los Términos & Condiciones.
                </p>
                <p className="text-justify my-2 ml-12">
                Cualquier duda sobre el uso de este dispositivo por favor llame a +58 212.8221260.
                </p>
              </li>
            </ul>
            <div className="mt-10 ">
              <p className="mt-5 my-3 text-right font-bold">
              ÚLTIMA ACTUALIZACIÓN: 9 de febrero de 2022
              </p>
            </div>
        </div>
      </div>
    );
  }
}

TermsConditionAcunsa.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withMobileDialog({ breakpoint: "xs" })(
  withStyles(styles)(TermsConditionAcunsa)
);
